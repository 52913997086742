<!--

	Die Componente dient als transparent Wrapper für alle Input Elemente

	Props:
		value	String 	Wird als value des Inputs verwendet
		label 	String 	Wird vor dem Input angezeigt

	Markup:
		<BaseCheckbox
			name="yourGroupName"
			:value="true"
			@change="myFunction( $event.target.value )"
		></BaseCheckbox>

	Changelog
		2020-07-27	init

-->

<template>
	<label class="BaseCheckbox" :class="elmClasses">
		<div class="BaseCheckbox__inner">
			<input
				ref="input"
				class="BaseCheckbox__input"
				type="checkbox"
				:required="required"
				v-model="model"
				@input="emit"
			/>
			<div class="BaseCheckbox__checkbox"
			     :class="{'BaseCheckbox__checkbox--isSelected' : model}"><span></span></div>
		</div>
		<div class="BaseCheckbox__label" v-if="label"><span v-html="label"></span></div>
		<!--
		<div class="BaseCheckbox__label" v-if="label" v-html="label"></div>
		-->
		<template v-if="debug">
			<br />
			<pre name="model">{{model}}</pre>
			<pre name="value">{{value}}</pre>
			<pre name="checkedValue">{{checkedValue}}</pre>
			<pre name="uncheckedValue">{{uncheckedValue}}</pre>
		</template>
	</label>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import { EventBus } from '@/event-bus.js'

	export default {
		name: 'BaseCheckbox',
		components: {},
		mixins: [],
		props: {
			value: {
				type: [String, Boolean],
				default: true,
			},
			checkedValue: {
				type: [String, Boolean],
				default: true,
			},
			uncheckedValue: {
				type: [String, Boolean],
				default: false,
			},
			label: {
				type: [String, Number, Boolean],
				default: false,
			},
			required: {
				type: [String, Boolean],
				default: false,
			},
			debug: {
				type: [Boolean],
				default: false,
			},
		},
		data() {
			return {
				model        : undefined,
			}
		},
		watch: {
			value: {
				// watch value and set checked state
				// this is needed to react on model changes from outside
				handler: function( to, from ) {
					const inputElm = this.$refs.input

					if( to === this.checkedValue ){
						inputElm.checked = true
						this.model = true
					}else{
						inputElm.checked = false
						this.model = false
					}

					const isChecked   = inputElm.checked
					const returnValue = isChecked ? this.checkedValue : this.uncheckedValue
					//this.$emit('input', returnValue)
				},
			},
		},
		computed: {
			elmClasses(){
				 let classes = []

				 //classes.push( 'BaseCheckbox--' + this.type )

				 return classes
			},
		},
		methods: {
			emit( event ){
				const isChecked   = event.target.checked
				const returnValue = isChecked ? this.checkedValue : this.uncheckedValue

				/*
				console.log('event:', event)
				console.log('isChecked', isChecked)
				console.log('returnValue', returnValue)
				console.log('')
				*/

				this.$emit('change', event)
				this.$emit('input', returnValue) // this changes parent v-model
			},
		},
		created() {},
		mounted() {
			this.model = this.value === this.checkedValue
		},
	}
</script>

<style lang="less">
	@import (reference) "./vars.less";
	@import (reference) "./mixins.less";

	.BaseCheckbox {	// css vars
		--height                : @checkboxElm[height];
		--paddingLeft           : @checkboxElm[paddingLeft];
		--paddingRight          : @checkboxElm[paddingRight];
		--labelPadding          : @checkboxElm[labelPadding];
		--border                : @checkboxElm[border];
		--hoverBorder           : @checkboxElm[hoverBorder];
		--focusBorder           : @checkboxElm[focusBorder];
		--activeBackgroundColor : @checkboxElm[activeBackgroundColor];
		--activeForegroundColor : @checkboxElm[activeForegroundColor];
	}
	.BaseCheckbox {
		//outline: 1px solid fade( red, 50);

		display: inline-flex;
		align-items: flex-start;
		cursor: pointer;

		&__inner {
			//background-color: fade( red, 15 );

			position: relative;
			height: @checkboxElm[height]; height: var(--height);
			display: flex;
			align-items: center;
			padding-left: @checkboxElm[paddingLeft]; padding-left: var(--paddingLeft);
			padding-right: @checkboxElm[paddingRight]; padding-right: var(--paddingRight);
		}
		&__input {
			position: absolute;
			top: 0; left: 0;
			right: 0; bottom: 0;
			height: 100%; width: 100%; // needed correct dimensions for validation tooltip placement
			opacity: 0;
		}
		&__checkbox {
			.checkboxMixin();
		}
		&__label {
			min-height: @checkboxElm[height]; min-height: var(--height);
			padding: @checkboxElm[labelPadding]; padding: var(--labelPadding);

			display: inline-block;
			display: flex;
			align-items: center;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
</style>
