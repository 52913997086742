<template>
	<div class="EventView view">

		<div class="minHeight minHeight--100">
			<AbMainHeader></AbMainHeader>
			<AbMainHeadline text="Veranstaltung"></AbMainHeadline>

			<div class="contentModule">
				<div class="contentModule__inner font hSpace hSpace--page maxWidth maxWidth--page">
					<AbEventAndNachlese :post="post" v-if="post"></AbEventAndNachlese>
				</div>
			</div>
		</div>

		<AbMainFooter></AbMainFooter>

		<!--
		<pre name="page.acf">{{page.acf}}</pre>
		<pre data-name="pages">{{pages}}</pre>
		-->

	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'
	import ViewMixin from '@/views/View.mixin.js'

	import AbMainHeader from '@/components/AbMainHeader.vue'
	import AbMainHeadline from '@/components/AbMainHeadline.vue'
	import AbEventAndNachlese from '@/components/AbEventAndNachlese.vue'
	import AbMainFooter from '@/components/AbMainFooter.vue'

	export default {
		name: 'EventView',
		components: {
			AbMainHeader,
			AbMainHeadline,
			AbEventAndNachlese,
			AbMainFooter,
		},
		mixins: [ restHandler, ViewMixin ],
		directives: {},
		props: {
		},
		data() {
			return {
				post: null,
			}
		},
		computed: {},
		methods: {},
		mounted() {
			// fetch posts
			this.restHandler__fetch({
				action : 'GET',
				route : '/wp-json/mh/v1/posts',
				params : {
					slug : this.$route.params.slug,
				},
				callback : (response) => {
					let results = response.data.result

					// convert acf.date string to int
					this._.forEach( results, (item, i)=>{
						const dateInt = parseInt( item.acf.date )
						const dateStr = '' + item.acf.date

						results[i].acf.date = dateInt
						results[i].acf.dateYear  = parseInt( dateStr.substring(0, 4) )
						results[i].acf.dateMonth = parseInt( dateStr.substring(4, 6) )
					})

					this.post = results[0]

					this.viewIsReady = true
				},
			})
		},
	}
</script>

<style lang="less">
	//@import "../less/includes/grid.include.less";
	@import "../less/vars.less";

	.EventView {
		//padding: 1em;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
