<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="AbHtmlEmbed contentModule" :class="elmClasses" v-if="html">
		<div class="AbHtmlEmbed__inner contentModule__inner font layout maxWidth maxWidth--page" :class="elmInnerClasses">
			<!--
			<div class="AbHtmlEmbed__embedWrapper"
				 :style="embedWrapperStyles"
				 v-html="html"></div>
				 <pre>{{ html }}</pre>
			-->

			<MhHtmlEmbedConsentWrapper
				:htmlCode="html"
				:overlayClass="'background--grey color--white'"
				:titleClass="''"
				:textClass="''"
				:buttonClass="'button font--serif font--sizeSmall background--pink color--white'"
				:disclaimerClass="''"
				:removeConsentClass="'font--serif font--sizeMini'"
			></MhHtmlEmbedConsentWrapper>
		</div>
		<!--
		<hr />
		<pre name="iframe">{{iframe}}</pre>
		<textarea style="width: 100%; height: 200px" v-model="html"></textarea>
		-->
	</div>
</template>

<script>
	import MhHtmlEmbedConsentWrapper from '@/vendor/MhHtmlEmbedConsentWrapper/v1 • vue 2+3/MhHtmlEmbedConsentWrapper.vue'
	import MhHtmlEmbed from '@/vendor/MhHtmlEmbed/v2.2 • vue 2+3/MhHtmlEmbed.vue'

	export default {
		name: 'AbHtmlEmbed',
		components: {
			MhHtmlEmbedConsentWrapper,
			MhHtmlEmbed,
		},
		mixins: [],
		props: {
			postType: {
				type: [String, Boolean],
				default: '',
			},
			html: {
				type: [String, Boolean],
				default: '',
			},
			layout: {
				type: [String, Boolean],
				default: '☰☰☰☰☰☰☰☰☰☰☰☰',
			},
		},
		data() {
			return {
				iframe : {
					height : undefined,
					width  : undefined,
				},
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
			elmInnerClasses(){
				let classes = []

				if( this.postType == 'page' ){
					classes.push('hSpace hSpace--page')
					//classes.push('font--size' + this._.upperFirst(this.fontSize))
				}else{
					//classes.push('font--sizeSmall')
				}

				classes.push('layout--' + this.layout)

				return classes
			},
			embedWrapperStyles(){
				const height = this.iframe.height
				const width  = this.iframe.width
				const ratio  = ( height && width ) ? ( height / width ) * 100 : undefined

				let styles = {}

				if( ratio ) styles.paddingBottom = ratio + '%'

				return styles
			},
		},
		methods: {},
		created() {
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted() {
			//console.log('html:', this.html)

			let frag   = document.createRange().createContextualFragment( this.html )
			let iframe = frag.querySelector('iframe')

			if( iframe ){
				let height = iframe.getAttribute('height')
				let width  = iframe.getAttribute('width')

				this.iframe.height = height ? parseFloat( height ) : undefined
				this.iframe.width  = width ? parseFloat( width ) : undefined

				/*
				console.log('frag:', frag)
				console.log('iframe:', iframe)
				console.log('height:', height)
				console.log('width:', width)
				*/
			}
		},
		destroyed () {
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.AbHtmlEmbed {

		.MhHtmlEmbedConsentWrapper__overlay {
			padding: 0.5em;
		}
		.MhHtmlEmbedConsentWrapper__overlayTitle {
			.font--sizeDefault;
			padding-bottom: 0.5em;
		}
		.MhHtmlEmbedConsentWrapper__overlayText {
			.font--sizeSmall;
		}
		.MhHtmlEmbedConsentWrapper__overlayButton {
			.font--sizeSmall;
			margin-top: .5em;
		}
		.MhHtmlEmbedConsentWrapper__overlayDisclaimer {
			.font--sizeMini;
			padding-top: 3em;
			line-height: 1.2em;
		}
		.MhHtmlEmbedConsentWrapper__removeConsentButton {
			//line-height: 1.2em;
		}

		/*
		&__embedWrapper {
			position: relative;
			padding-bottom: 50%;

			& > * {
				position: absolute;
				top: 0; left: 0; bottom: 0; right: 0;
				height: 100%; width: 100%;
			}
		}
		*/
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
