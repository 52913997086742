<template>
	<div class="NotFoundView view">

		<div class="minHeight minHeight--100">
			<AbMainHeader></AbMainHeader>
			<AbMainHeadline text="Inhalt nicht gefunden"></AbMainHeadline>
		</div>

		<AbMainFooter></AbMainFooter>

	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import ViewMixin from '@/views/View.mixin.js'

	import AbMainHeader from '@/components/AbMainHeader.vue'
	import AbMainHeadline from '@/components/AbMainHeadline.vue'
	import AbMainFooter from '@/components/AbMainFooter.vue'

	export default {
		name: 'NotFoundView',
		components: {
			AbMainHeader,
			AbMainHeadline,
			AbMainFooter,
		},
		mixins: [ ViewMixin ],
		props: {},
		data() {
			return {}
		},
		computed: {
			currentUrl(){
				return window.location
			}
		},
		methods: {},
		mounted() {
			this.viewIsReady = true
		}
	}
</script>

<style lang="less" scoped>
	@import "../less/vars.less";

	.NotFoundView {
		padding: 1em;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
