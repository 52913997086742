<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="AbContentModules" :class="elmClasses">
		<div v-for="(cm, index) in contentModules"
			:key="index" :is="getComponentName(cm)" :postType="post.type"
			v-bind="getComponentBind(cm)">
			<pre data-name="cm">{{getComponentName(cm)}}</pre>
			<pre data-name="cm">{{cm}}</pre>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import AbSubHeadline from '@/components/AbSubHeadline.vue'
	import AbRichText from '@/components/AbRichText.vue'
	import AbTextAndImage from '@/components/AbTextAndImage.vue'
	import AbImage from '@/components/AbImage.vue'
	import AbSlider from '@/components/AbSlider.vue'
	import AbMessageBoxAndImage from '@/components/AbMessageBoxAndImage.vue'
	import AbMessageBoxAndText from '@/components/AbMessageBoxAndText.vue'
	import AbTeaserBoxAndImage from '@/components/AbTeaserBoxAndImage.vue'
	import AbBookingForm from '@/components/AbBookingForm.vue'
	import AbLine from '@/components/AbLine.vue'
	import AbSpacer from '@/components/AbSpacer.vue'
	import AbHtmlEmbed from '@/components/AbHtmlEmbed.vue'
	import AbAnchor from '@/components/AbAnchor.vue'

	export default {
		name: 'AbContentModules',
		components: {
			AbSubHeadline,
			AbRichText,
			AbTextAndImage,
			AbImage,
			AbSlider,
			AbMessageBoxAndImage,
			AbMessageBoxAndText,
			AbTeaserBoxAndImage,
			AbBookingForm,
			AbLine,
			AbSpacer,
			AbHtmlEmbed,
			AbAnchor,
		},
		mixins: [],
		props: {
			post: [Object, Boolean],
			//text: [String],
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			contentModules(){
				const contentModules = this._.get( this.post, 'acf.contentModules' )

				return contentModules
			},
		},
		methods: {
			getComponentName( obj ){
				return 'Ab' + this._.upperFirst( obj['acf_fc_layout'] )
			},
			getComponentBind( obj ){
				//delete obj['acf_fc_layout']

				return obj
			},
			getLinkWithoutHostname( url ){
				let _url = new URL( url )
				return _url.pathname
			},
		},
		created() {
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted() {},
		destroyed () {
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.AbContentModules {
		& > .contentModule {
			margin-bottom: 1em;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
