/**
 *	In dieser Datei können für verschiedene Environments,
 *  basierend auf dem jeweiligen hostname, angelegt werden.
 *  Für die verschiedeneen Environments können Key-Value-Pairs
 *  hinterlegt werden.
 *
 *  Das ist z.B. sinnvoll, wenn die locale Dev-Umgebung
 *  eine andere Rest-API benötigt als die Live-Umgebung.
 */

export default [
	{
		name: 'local',
		hostname: 'localhost',
		restHost: 'http://abtei-st-stephan.local'
	},
	{
		name: 'local2',
		hostname: 'abtei-st-stephan.local',
		restHost: 'http://abtei-st-stephan.local'
	},
	{
		name: 'stage',
		hostname: 'dev.abtei-st-stephan.de',
		restHost: 'https://dev.abtei-st-stephan.de'
	},
	{
		name: 'live',
		hostname: 'abtei-st-stephan.de',
		restHost: 'https://abtei-st-stephan.de'
	},
]
