<!-- 

	Die Componente dient als transparent Wrapper für Textarea Elemente
	
	Props:
		
	
	Markup:
		<BaseTextarea
			@change="myFunction( $event.target.value )"
			:value="someNumber"
		></BaseTextarea>
		
		<BaseTextarea
			label="Vorname"
			@change="myFunction( $event.target.value )"
			:value="someString"
		></BaseTextarea>
	
	Changelog
		2020-07-27	init
		2019-06-13	init

-->

<template>
	<div class="BaseTextarea" :class="elmClasses">
		<div class="BaseTextarea__inner">
			<textarea
				ref="textarea"
				class="BaseTextarea__textarea"
				:placeholder="placeholder"
				v-model="model"
				@input="emit"
				@resize="onResize"
			/>
		</div>
		<template v-if="debug">
			<br />
			<pre name="BaseTextarea.value">{{value}}</pre>
			<pre name="BaseTextarea.model">{{model}}</pre>
		</template>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import { EventBus } from '@/event-bus.js'

	export default {
		name: 'BaseInput',
		components: {},
		mixins: [],
		props: {
			value: {
				type: [String, Number, Boolean],
				default: '',
			},
			placeholder: {
				type: [String, Number, Boolean],
				default: '',
			},
			debug: {
				type: [Boolean],
				default: false,
			},
		},
		data() {
			return {
				model : null
			}
		},
		watch: {
			value: {
				// watch value and set model
				// this is needed to react on model changes from outside
				handler: function( to, from ) { 
					if( this.model != to ) this.model = to
				},
			},
		},
		computed: {
			elmClasses(){
				 let classes = []
				 
				 //classes.push( 'BaseInput--' + this.type )
				 
				 return classes
			},
		},
		methods: {
			emit( event ) {
				this.$emit('input', this.model)
				this.$emit('change', event)
			},
			onResize( event ) {
				console.log('onResize', event)
			},
		},
		created() {},
		mounted() {
			this.model = this._.clone( this.value )
		},
	}
</script>

<style lang="less">
	@import (reference) "./vars.less";

	.BaseTextarea { // css vars
		--height                : @textareaElm[height];
		--padding               : @textareaElm[padding];
		--borderRadius          : @textareaElm[borderRadius];
		--border                : @textareaElm[border];
		--hoverBorder           : @textareaElm[hoverBorder];
		--backgroundColor       : @textareaElm[backgroundColor];
	}

	.BaseTextarea {
		position: relative;
		width: 100%;

		&__inner {
			position: relative;
			//height: @textareaElm[height]; height: var(--height);
			width: 100%;
			background-color: @textareaElm[backgroundColor]; background-color: var(--backgroundColor);
			border-radius: @textareaElm[borderRadius]; border-radius: var(--borderRadius);
			border: @textareaElm[border]; border: var(--border);
			color: inherit;		
		}
		&__textarea {
			margin: 0;
			margin-bottom: -2px;
			max-width: 100%;
			width: 100%;
			//height: 100%;
			height: @textareaElm[height]; height: var(--height);
			padding: @textareaElm[padding]; padding: var(--padding);
			min-height: 4em;

			border: none;
			outline: none;
			background-color: transparent;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			font: inherit;
			color: inherit;
			resize: vertical;
			
			&::placeholder { 				
				opacity: 0.5;
				color: currentColor;
			}
			&:focus {
				outline: none;
				opacity: 1;
								
				&::placeholder { 
					opacity: 0;
				}
			}
		}
	}
	.BaseTextarea:focus-within,
	.BaseTextarea:hover {
		.BaseTextarea__inner {
			border: @textareaElm[hoverBorder];
		}
	}
</style>
