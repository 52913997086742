<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="AbSpacer contentModule" :class="elmClasses">
		<div class="AbSpacer__inner contentModule__inner hSpace hSpace--page maxWidth maxWidth--page">
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'AbSpacer',
		components: {},
		mixins: [],
		props: {},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {},
		created() {
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted() {},
		destroyed () {
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.AbSpacer {
		padding: 1em 0;

		@media @mq[md] {
			padding: 0.5em 0;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
