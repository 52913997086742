<!--

	Die Componente dient als transparent Wrapper für alle Input Elemente

	Props:
		value	String 	Wird als value des Inputs verwendet
		label 	String 	Wird vor dem Input angezeigt

	Markup:
		<BaseRadio
			name="yourGroupName"
			@change="myFunction( $event.target.value )"
			:value="true"
		></BaseRadio>

	Changelog
		2020-07-27	init

-->

<template>
	<label class="BaseRadio" :class="elmClasses">
		<div class="BaseRadio__inner">
			<input
				ref="input"
				type="radio"
				class="BaseRadio__input"
				:name="name"
				v-model="value"
				:value="checkedValue"
				@input="emit"
			/>
			<div class="BaseRadio__radio"
				 :class="{'BaseRadio__radio--isSelected' : model}"><span></span></div>
		</div>
		<div class="BaseRadio__label" v-if="label"><span v-html="label"></span></div>
		<!--
		<div class="BaseRadio__label" v-if="label" v-html="label"></div>
		-->
		<template v-if="debug">
			<br />
			<pre name="BaseRadio.model">{{model}}</pre>
			<pre name="BaseRadio.value">{{value}}</pre>
			<pre name="BaseRadio.checkedValue">{{checkedValue}}</pre>
			<pre name="BaseRadio.name">{{name}}</pre>
		</template>
	</label>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import { EventBus } from '@/event-bus.js'

	export default {
		name: 'BaseRadio',
		components: {},
		mixins: [],
		props: {
			value: {
				type: [String, Boolean],
				default: '',
			},
			checkedValue: {
				type: [String, Boolean],
				default: '',
			},
			name: {
				type: [String],
				default: '',
			},
			label: {
				type: [String, Number, Boolean],
				default: false,
			},
			debug: {
				type: [Boolean],
				default: false,
			},
		},
		data() {
			return {
				model : undefined,
			}
		},
		watch: {
			value: {
				// watch value and set checked state
				// this is needed to react on model changes from outside
				handler: function( to, from ) {
					const inputElm = this.$refs.input

					if( to === this.checkedValue ){
						inputElm.checked = true
						this.model = true
					}else{
						inputElm.checked = false
						this.model = false
					}

					const isChecked   = inputElm.checked
					const returnValue = isChecked ? this.checkedValue : this.uncheckedValue
					//this.$emit('input', returnValue)
				},
			},
		},
		computed: {
			elmClasses(){
				 let classes = []

				 //classes.push( 'BaseRadio--' + this.type )

				 return classes
			},
		},
		methods: {
			emit( event ){
				const isChecked   = event.target.checked
				const returnValue = this.checkedValue

				/*
				console.log('event:', event)
				console.log('isChecked', isChecked)
				console.log('returnValue', returnValue)
				console.log('')
				*/

				if( isChecked ){
					this.$emit('change', event)
					this.$emit('input', returnValue) // this changes parent v-model
				}
			},
		},
		created() {},
		mounted() {
			this.model = this.value === this.checkedValue
		},
	}
</script>

<style lang="less">
	@import (reference) "./vars.less";
	@import (reference) "./mixins.less";

	.BaseRadio { // css vars
		--height                : @radioElm[height];
		--paddingLeft           : @radioElm[paddingLeft];
		--paddingRight          : @radioElm[paddingRight];
		--labelPadding          : @radioElm[labelPadding];
		--border                : @radioElm[border];
		--hoverBorder           : @radioElm[hoverBorder];
		--focusBorder           : @radioElm[focusBorder];
		--activeBackgroundColor : @radioElm[activeBackgroundColor];
		--activeForegroundColor : @radioElm[activeForegroundColor];
	}
	.BaseRadio {
		//outline: 1px solid fade( red, 50);
		display: inline-flex;
		align-items: flex-start;
		cursor: pointer;

		&__inner {
			//background-color: fade( red, 15 );

			position: relative;
			height: @radioElm[height]; height: var(--height);
			display: flex;
			align-items: center;
			padding-left: @radioElm[paddingLeft]; padding-left: var(--paddingLeft);
			padding-right: @radioElm[paddingRight]; padding-right: var(--paddingRight);
		}
		&__input {
			position: absolute;
			top: 0; left: 0;
			right: 0; bottom: 0;
			height: 100%; width: 100%; // needed correct dimensions for validation tooltip placement
			opacity: 0;
		}
		&__radio {
			.radioMixin();
		}
		&__label {
			min-height: @radioElm[height]; min-height: var(--height);
			padding: @radioElm[labelPadding]; padding: var(--labelPadding);

			display: flex;
			align-items: center;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
</style>
