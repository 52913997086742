<!--

	Die Componente dient als transparent Wrapper für alle Input Elemente

	Props:
		value	String 	Wird als value des Inputs verwendet
		label 	String 	Wird vor dem Input angezeigt

	Markup:
		<BaseText
			@change="myFunction( $event.target.value )"
			:value="'Mein Standardwert'"
		></BaseText>

	Changelog
		2020-07-27	init

-->

<template>
	<div class="BaseText" :class="elmClasses">
		<div class="BaseText__inner">
			<input
				ref="input"
				type="text"
				class="BaseText__input"
				:placeholder="placeholder"
				:required="required"
				:disabled="disabled"
				v-model="model"
			  	@input="emit"
			/>
			<span 	class="BaseText__clearBtn"
				:class="{'BaseText__clearBtn--isVisible' : hasClearButton && model }"
				role="button"
				@click="clickClearBtn"
			></span>
		</div>
		<template v-if="debug">
			<br />
			<pre name="BaseText.value">{{value}}</pre>
			<pre name="BaseText.model">{{model}}</pre>
		</template>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import { EventBus } from '@/event-bus.js'

	export default {
		name: 'BaseText',
		components: {},
		mixins: [],
		props: {
			value: {
				type: [String, Number, Boolean],
				default: '',
			},
			placeholder: {
				type: [String, Number, Boolean],
				default: '',
			},
			required: {
				type: [String, Boolean],
				default: false,
			},
			disabled: {
				type: [String, Boolean],
				default: false,
			},
			hasClearButton: {
				type: [String, Boolean],
				default: false,
			},
			debug: {
				type: [Boolean],
				default: false,
			},
		},
		data() {
			return {
				model : null,
			}
		},
		watch: {
			value: {
				// watch value and set model
				// this is needed to react on model changes from outside
				handler: function( to, from ) {
					if( this.model != to ) this.model = to
				},
			},
		},
		computed: {
			elmClasses(){
				 let classes = []

				 if( this.required ) classes.push( this.$options.name + '--isRequired' )
				 if( this.disabled ) classes.push( this.$options.name + '--isDisabled' )
				 if( this.hasClearButton ) classes.push( this.$options.name + '--hasClearButton' )

				 return classes
			},
		},
		methods: {
			emit( event ) {
				//console.log('BaseText emit')
				this.$emit('input', this.model)
				this.$emit('change', event)
			},
			clickClearBtn( event ){
				//console.log('BaseText clickClearBtn')
				this.model = ''
				this.emit( event )
			},
		},
		created() {},
		mounted() {
			this.model = this._.clone( this.value )
		},
	}
</script>

<style lang="less">
	@import (reference) "./vars.less";
	@import (reference) "./mixins.less";

	.BaseText { // css vars
		--height                : @textElm[height];
		--paddingLeft           : @textElm[paddingLeft];
		--borderRadius          : @textElm[borderRadius];
		--border                : @textElm[border];
		--hoverBorder           : @textElm[hoverBorder];
		--backgroundColor       : @textElm[backgroundColor];
		--activeBackgroundColor : @baseElm[activeBackgroundColor];
		--activeForegroundColor : @baseElm[activeForegroundColor];
	}
	.BaseText {
		position: relative;
		width: 100%;

		&__inner {
			position: relative;
			width: 100%;
			height: @textElm[height]; height: var(--height);
			background-color: @textElm[backgroundColor]; background-color: var(--backgroundColor);
			border-radius: @textElm[borderRadius]; border-radius: var(--borderRadius);
			border: @textElm[border]; border: var(--border);
			color: inherit;
		}
		&__input {
			max-width: 100%;
			width: 100%;
			height: 100%;
			text-indent: @textElm[paddingLeft]; text-indent: var(--paddingLeft);
			//cursor: pointer;

			border: none;
			outline: none;
			background-color: transparent;
			-webkit-appearance: none;
    		-moz-appearance: none;
    		appearance: none;
			font: inherit;
			color: inherit;
			padding: 0;
			margin: 0;

			&::placeholder {
				opacity: 0.5;
				color: currentColor;
			}
			&:focus {
				outline: none;
				opacity: 1;

				&::placeholder {
					opacity: 0;
				}
			}
		}
		&__clearBtn {
			position: absolute;
			top: 50%; right: 0;
			height: 1.2em;
			width: 1.2em;
			display: flex;
			justify-content: center;
			align-items: center;

			transition: all 0.1s ease;
			transform: translateY(-50%) translateX(-50%);
			font-size: @textElm[height]/2; font-size: calc( var(--height) / 2 );
			line-height: 1em;
			//color: @textElm[backgroundColor];
			//text-decoration: none;

			border-radius: 50%;
			background-color: fade( black, 65 );
			opacity: 0;
			pointer-events: none;
			cursor: pointer;

			&::after, &::before {
				position: absolute;
				top: calc( 50% + 0px ); left: calc( 50% );
				width: 0.8em; height: 2px;
				//transform: translateY(-64%) translateX(-54%);
				background-color: white;
				transform-origin: left top;
				content: "";
			}
			&::after {
				transform: rotate(45deg) translateY(-50%) translateX(-50%);
			}
			&::before {
				transform: rotate(-45deg) translateY(-50%) translateX(-50%);
			}

			&--isVisible {
				pointer-events: auto;
				opacity: 0.5;
			}
			&--isVisible:hover {
				opacity: 1;
			}
		}
	}
	.BaseText:focus-within,
	.BaseText:hover:not(.BaseText--isDisabled) {
		.BaseText__inner {
			border: @textElm[hoverBorder]; border: var(--hoverBorder);
		}
	}

	.BaseText--isRequired {}
	.BaseText--isDisabled {
		.BaseText__input {
			opacity: 0.75;
		}
	}
</style>
