<!--

	Die Componente dient als transparent Wrapper für Select Elemente

	Props:

		value		String	Wird als value des Selects verwendet
		label		String	Erste Zeile im <select> z.B. "Bitte wählen ..."
		options		Array	Werden als <option> verwendet


	Markup:

		<BaseSelect
			:value="'Heiko'"
			:label="'Choose ...'"
			:options="[
				'Mario',
				'Heiko',
				'Silvio'
			]"
			@change="valueChanged( $event )"
		></BaseSelect>

		<BaseSelect
			:value="'heiko'"
			:label="'Choose ...'"
			:multiple="true"
			❌:options="[
				{ label : 'Mario',  value : "mario", selected : true },
				{ label : 'Heiko',  value : "heiko", selected : false },
				{ label : 'Silvio', value : "silvio", selected : false },
			]"
			@change="valueChanged( $event )"
		></BaseSelect>

	Changelog
		2020-09-02	refactored, added multiple
		2020-08-07	removed label
		2019-06-13	init

-->

<template>
	<div class="BaseSelect" :class="elmClasses">
		<div class="BaseSelect__inner">
			<select
				ref="selectElm"
				class="BaseSelect__select"
				:class="{'BaseSelect__select--isUnset' : !model}"
				:multiple="multiple"
				v-model="model"
				@change="emit"
				>
				<option
					class="BaseSelect__option BaseSelect__option--label"
					v-if="label && !multiple"
					value=""
					v-html="label"
					disabled 
					>{{label}}</option>
				<option
					class="BaseSelect__option"
					v-for="(option, index) in options"
					:key="index"
					:value="option"
					v-html="option"
					@mousedown="clickOption( $event, option, index )"
				></option>
			</select>
			<span class="BaseSelect__icon" v-if="!multiple"></span>
			<div  class="BaseSelect__filterBar" v-if="multiple && showFilterBar">
				<div class="BaseSelect__filterBarCountSelected">
					{{countSelectedOptions}}
				</div>
				<input 
					class="BaseSelect__filterBarInput" 
					placeholder="Filter" 
					v-model="filterModel" 
					@keydown.esc="$event.target.blur()" />
				<div class="BaseSelect__filterBarCountResults">
					{{countFilteredOptions}} / {{internalOptions.length}}
				</div>
			</div>
			<div  class="BaseSelect__optionsList" v-if="multiple">
				<div  class="BaseSelect__optionsItem" v-for="(option, i) in internalOptions" :key="i"
				      :class="{
						  'BaseSelect__optionsItem--isSelected' : option.selected,
						  'BaseSelect__optionsItem--isHidden'   : !option.isVisible,
					  }"
					  @click="clickFakeOption( $event, option )"
					  >
					<span class="BaseSelect__checkbox"
					      :class="{'BaseSelect__checkbox--isSelected' : option.selected}"
				    ></span>
					<span v-html="option.label"></span>
				</div>
			</div>
		</div>
		<template v-if="debug">
			<br />
			<pre name="BaseSelect.model">{{model}}</pre>
			<pre name="BaseSelect.value">{{value}}</pre>
			<pre name="BaseSelect.options">{{options}}</pre>
			<pre name="BaseSelect.internalOptions">{{internalOptions}}</pre>
		</template>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import { EventBus } from '@/event-bus.js'

	export default {
		name: 'BaseSelect',
		components: {},
		mixins: [],
		props: {
    		value: {
      			type: [String, Number, Array, Boolean],
      			default: '',
    		},
    		label: {
      			type: [String, Number, Boolean],
      			default: '',
    		},
    		multiple: {
      			type: [String, Boolean],
      			default: false,
    		},
    		showFilterBar: {
      			type: [String, Boolean],
      			default: true,
    		},
    		options: {
      			type: [Array],
      			default: function(){ return [] },
    		},
    		debug: {
				type: [Boolean],
      			default: false,
    		},
    	},
		data() {
			return {
				model                   : null,
				internalOptions         : [],
				doWatchInternalOptions  : true,
				doUpdateInternalOptions : true,
				filterModel             : '',
			}
		},
		watch: {
			value: {
				// watch prop value and set to model
				// this is needed to react on model changes from outside
				handler: function( to, from ) { 
					//console.log('watcher value', from, to)
					
					this.model = to
					this.updateInternalOptions()
				},
				immediate: true,
			},
			filterModel: {
				handler: function( to, from ) { 
					//console.log('watcher filterModel', from, to)
					
					this.filterModel = this.filterModel.trim() // no spaces allowed
					this.updateInternalOptions()
				},
				immediate: true,
			},
			options: {
				// watch prop options and set internalOptions
				// this is needed to react on options changes from outside
				handler: function( to, from ) {
					//console.log('watcher options', from, to)
					
					this.setInternalOptions()
				},
				immediate: true,
			},
			/*
			internalOptions: {
				handler: function( to, from ) {
					console.log('watcher internalOptions', from, to)						
					
					if( this.doWatchInternalOptions ){
						//
					}
				},
				deep : true,
			},
			*/
		},
		computed: {
			elmClasses(){
				let classes = []

				if( this.multiple ) classes.push('BaseSelect--isMultiple')

				return classes
			},
			countFilteredOptions(){
				const results = this.internalOptions.filter( item => item.isVisible === true )
				
				return results.length
			},
			countSelectedOptions(){
				const results = this.internalOptions.filter( item => item.selected === true )
				
				return results.length
			},
		},
		methods: {
			emit( event ) { // emit input (values only) and change (event)
				const returnValue = this._.isArray( this.model ) ? [...this.model] : this.model

				//console.log('BaseSelect emit:', event, returnValue);

				this.$emit('change', event)
				this.$emit('input', returnValue) // this changes parent v-model
			},
			clickOption( e, option, index ){ // implement later
				// handles only multiple selects
				if( !this.multiple ) return
				
				if( this.multiple ) return
				
				const model    = this.model
				const isActive = model.includes( option )
				
				/*
				console.group('clickOption:', option)
				console.log('       e:', e)
				console.log('  option:', option)
				console.log('   index:', index)
				console.log('   model:', model)
				console.log('isActive:', isActive)
				console.groupEnd()
				*/
				
				if( !isActive ){
					model.splice( index, 0, option )
				}else{
					model.splice(index, 1)
				}
				
				e.stopPropagation();
				e.preventDefault();
			},
			setInternalOptions(){
				const selectedValues      = this.value
				const options             = this.options
				this.doWatchInternalOptions = false
				this.internalOptions      = []
				
				options.forEach((item) => {
					const newItem = {
						label     : item,
						value     : item,
						isVisible : true,
						selected  : (selectedValues && this._.isArray(selectedValues)) ? selectedValues.includes( item ) : false, 
					}
					newItem.isVisible = this.isVisibleOption( newItem )
					this.internalOptions.push( newItem )
				})
				
				this.doWatchInternalOptions = true
			},
			updateInternalOptions(){
				const selectedValues      = this.value
				const internalOptions     = this.internalOptions
				
				this.doWatchInternalOptions = false
				
				/*
				console.group('updateInternalOptions()')	
				console.log('selectedValues:', selectedValues)	
				console.log('internalOptions:', internalOptions)
				console.groupEnd()	
				*/
				
				internalOptions.forEach((item, index) => {
					item.isVisible = this.isVisibleOption( item )
					item.selected  = (selectedValues && this._.isArray(selectedValues)) ? selectedValues.includes( item.value ) : false
				})
				
				this.doWatchInternalOptions = true
			},
			isVisibleOption( option ){
				const useFilterModel = this.filterModel && this.filterModel.length
				let isVisible = true
				
				if( useFilterModel ){
					const testValue = option.value.toLowerCase()
					const testAgainstValue = this.filterModel.toLowerCase()
					const isMatching = testValue.includes( testAgainstValue )
					//console.log( index, testValue, testAgainstValue, isMatching )
					isVisible = isMatching
				}
				
				return isVisible
			},
			clickFakeOption( e, option ){
				const oldState = option.selected
				const newState = !option.selected
				const internalOptions = this.internalOptions
				const newValue = this.multiple ? [] : ''
				
				option.selected = newState
				
				internalOptions.forEach((item) => {
					if( this.multiple && item.selected ){
						newValue.push( item.value )
					}
				})
				
				/*
				console.group('clickFakeOption()')	
				console.log('option:', option)	
				console.log('option.value:', option.value)	
				console.log('oldState:', oldState)	
				console.log('newState:', newState)	
				console.log('newValue:', newValue)	
				console.groupEnd()	
				*/
				
				// set changed model and emit
				this.model = newValue
				this.emit( e )
			},
		},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import (reference) "./vars.less";
	@import (reference) "./mixins.less";

	.BaseSelect {
		position: relative;
		width: 100%;
		user-select: none;

		&__inner {
			position: relative;
			height: @selectElm[height];
			background-color: @selectElm[backgroundColor];
			border-radius: @selectElm[borderRadius];
			border: @selectElm[border];
			color: inherit;
			cursor: pointer;
		}
		&__select {
			max-width: 100%;
			width: 100%;
			height: 100%;
			text-indent: @selectElm[paddingLeft];
			cursor: pointer;

			border: none;
			outline: none;
			background-color: transparent;
			-webkit-appearance: none;
    		-moz-appearance: none;
    		appearance: none;
			font: inherit;
			color: inherit;
			padding: 0;
			margin: 0;			
		}
		&__select--isUnset { // lighten "Choose ..."
			opacity: 0.5;
		}
		&__select--isUnset:hover {
			opacity: 0.65;
		}
		&__option {
			padding-left: @selectElm[paddingLeft];
			background-color: transparent;
			//-webkit-appearance: none;
			//-moz-appearance: none;
			//appearance: none;
		}
		&__icon {
			//background-color: fade(red, 60);

			position: absolute;
			top: 0; right: 0;
			height: @selectElm[height];
			width: 2em;
			opacity: 0.6;
			color: currentColor;

			display: flex;
			justify-content: center;
			align-items: center;
			pointer-events: none;

			&::before {
				font-size: 0.65em;
				line-height: 1em;
				content: "▼";
			}
		}
	}
	.BaseSelect:hover {		
		.BaseSelect__inner { border: @selectElm[hoverBorder]; }
		.BaseSelect__icon { opacity: 1; }
	}
	.BaseSelect--isMultiple {
		.BaseSelect__inner {
			height: auto;
			padding: 0;
			/*
			min-height: @selectElm[multipleMinHeight];
			max-height: @selectElm[multipleMaxHeight];
			overflow: hidden;
			overflow-y: auto;
			*/
		}
		.BaseSelect__select { // hidden and replaced by .BaseSelect__optionsList
			display: none;
		}
		.BaseSelect__filterBar {
			position: relative;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding: 0 0.25em;
			height: @selectElm[height];
			border-bottom: @selectElm[border];
			background-color: @selectElm[filterBarBackgroundColor];
			
			&CountSelected,
			&Input,
			&CountResults {
				font-size: 0.85em;
			}
			&Input {
				max-width: 100%;
				width: 200px;
				border-radius: @selectElm[height];
				//width: 80%;
				height: @selectElm[height]*0.75;
				text-indent: @selectElm[paddingLeft]; 
				//text-indent: var(--paddingLeft);
				//cursor: pointer;

				transition: all 0.2s ease;
				border: none;
				outline: none;
				background-color: white;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				font-family: inherit;
				color: inherit;
				padding: 0;
				margin: 0;
				
				&::placeholder { 				
					opacity: 0.35;
					color: currentColor;
				}
				&:focus {
					outline: none;
					opacity: 1;
					width: 100%;
									
					&::placeholder { 
						//opacity: 0;
					}
				}
			}
			&CountResults {
				//background-color: fade( red, 20 );
				background-color: white;
				
				position: absolute;
				top: 0.4em; bottom: 0.4em; right: 0.25em;
				display: flex;
				align-items: center;
				padding: 0 0.75em;
				color: fade( black, 50 );
				border-top-right-radius: 1em;
				border-bottom-right-radius: 1em;
			}
			&CountSelected {
				display: none !important;
				//background-color: fade( red, 20 );
				
				display: flex;
				justify-content: center;
				border-radius: 1em;
				width: 2em;
				background-color: @selectElm[activeBackgroundColor];
				color: @selectElm[activeForegroundColor];
			}
		}
		.BaseSelect__optionsList {
			min-height: @selectElm[multipleMinHeight];
			max-height: @selectElm[multipleMaxHeight];
			overflow: hidden;
			overflow-y: auto;
		}
		/*
		.BaseSelect__option { // hidden and replaced by .BaseSelect__optionsItem
			height: @selectElm[height];
			display: flex;
			align-items: center;

			&:not(:first-child){ border-top: @selectElm[border]; }
		}
		.BaseSelect__option--label {
			display: none;
		}
		*/
		
		.BaseSelect__optionsItem {
			height: @selectElm[height];
			padding-left: @selectElm[paddingLeft];
			display: flex;
			align-items: center;
			white-space: nowrap;
			overflow: hidden;
			//text-overflow: ellipsis;
			transition: all 0.1s ease;
			
			&:not(:first-child){ border-top: @selectElm[border]; }
			//&::before { content: "☑" }
			&::before { 
				//content: "☐";
				//padding-right: 0.25em; 
				//font-size: 1.2em;
			}
		}
		.BaseSelect__optionsItem--isSelected {
			background-color: @selectElm[activeBackgroundColor];
			color: @selectElm[activeForegroundColor];
		}
		.BaseSelect__optionsItem--isHidden {
			//color: red;
			height: 0px;
			margin-top: -1px;
			border-color: transparent !important;
			background-color: transparent !important;
		}
		.BaseSelect__checkbox {
			.checkboxMixin();
			
			margin-right: 0.5em;
		}
	}
</style>
