<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="AbAnchor contentModule" :class="elmClasses" v-if="name">
		<div class="AbAnchor__inner contentModule__inner font hSpace hSpace--page maxWidth maxWidth--page">
			<div :id="name"></div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'AbAnchor',
		components: {},
		mixins: [],
		props: {
			name: [String, Boolean],
		},
		data() {
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
		destroyed () {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.AbAnchor {
		//height: 2em;
		//background-color: red;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
