<template>
	<div class="EventsView view">

		<div class="minHeight minHeight--100">
			<AbMainHeader></AbMainHeader>
			<div class="AbEvents contentModule">
				<div class="AbEvents__inner contentModule__inner font hSpace hSpace--page maxWidth maxWidth--page">

					<div class="AbEvents__nav">
						<h2 class="flex flex--middle font font--serif font--colorBlue">
							<span class="noWrap">Veranstaltungen {{currentYear}}</span>
						</h2>
						<nav class="flex flex--middle AbEvents__navFilter font font--colorLighterBlue font--bold font--sizeSmall">
							<button
								class="AbEvents__navFilterBtn noWrap"
								:class="{'AbEvents__navFilterBtn--isActive' : (filter === activeFilter)}"
								v-for="(filter, i) in eventsFilters" :key="i"
								@click="clickFilterBtn( filter )"
							>{{filter}}</button>
						</nav>
					</div>

					<!--
					<pre name="activeFilter">{{activeFilter}}</pre>
					<pre name="eventsYears">{{eventsYears}}</pre>
					<pre name="groupedEvents.length">{{groupedEvents.length}}</pre>
					-->

					<template v-if="posts && posts.length">
						<div class="" v-for="(year, i) in groupedEvents" :key="i">
							<div class="" v-for="(month, j) in year" :key="j">

								<div class="AbEvents__groupTitle font font--serif">
									<h3>{{ monthNames[j-1] }} {{ i }}</h3>
								</div>

								<AbEventAndNachlese v-for="(post, k) in month" :key="k" :post="post"></AbEventAndNachlese>

							</div>
						</div>
					</template>
					<template v-else>
						<AbRichText
							:text="'<br/>Derzeit keine Veranstaltungen.'"
						></AbRichText>
					</template>


				</div>
			</div>
		</div>

		<AbMainFooter></AbMainFooter>

		<!--
		<pre name="page.acf">{{page.acf}}</pre>
		-->

	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'
	import ViewMixin from '@/views/View.mixin.js'

	import AbMainHeader from '@/components/AbMainHeader.vue'
	import AbRichText from '@/components/AbRichText.vue'
	import AbEventAndNachlese from '@/components/AbEventAndNachlese.vue'
	import AbMainFooter from '@/components/AbMainFooter.vue'

	export default {
		name: 'EventsView',
		components: {
			AbMainHeader,
			AbRichText,
			AbEventAndNachlese,
			AbMainFooter,
		},
		mixins: [ restHandler, ViewMixin ],
		directives: {},
		props: {},
		data() {
			return {
				posts : null,
				eventsYears : [],
				validFilters : [
					'Gottesdienst',
					'Konzert',
					'Ausstellung',
					'Benediktinisches Lehrhaus',
				],
				eventsFilters : [],
				activeFilter : '',
				monthNames : [
					'Januar',
					'Februar',
					'März',
					'April',
					'Mai',
					'Juni',
					'Juli',
					'August',
					'September',
					'Oktober',
					'November',
					'Dezember',
				],
			}
		},
		computed: {
			groupedEvents(){
				let eventGroups  = {}
				let posts        = this.posts
				let activeFilter = this.activeFilter

				//console.log('----')

				if( activeFilter ){
					let _posts = []
					this._.forEach( posts, (item, i)=>{
						let cat  = item.acf.category

						if( cat == activeFilter ){
							_posts.push( item )
						}

						//console.log('item.acf.dateYear', item.acf.dateYear)
						//console.log('item.acf.dateMonth', item.acf.dateMonth)
					})
					posts = _posts
				}

				// set years
				this._.forEach( posts, (item, i)=>{
					let dateYear = item.acf.dateYear
					let dateMonth = item.acf.dateMonth

					eventGroups[dateYear] = {}

					//console.log('item.acf.dateYear', item.acf.dateYear)
					//console.log('item.acf.dateMonth', item.acf.dateMonth)
				})
				// set months
				this._.forEach( posts, (item, i)=>{
					let dateYear  = item.acf.dateYear
					let dateMonth = item.acf.dateMonth

					eventGroups[dateYear][dateMonth] = []
				})
				// set events into year+month
				this._.forEach( posts, (item, i)=>{
					let dateYear  = item.acf.dateYear
					let dateMonth = item.acf.dateMonth

					//eventGroups[dateYear][dateMonth].push( item.title.rendered )
					eventGroups[dateYear][dateMonth].push( item )
				})

				return eventGroups
			},
			currentYear(){
				return new Date().getFullYear()
			},
		},
		methods: {
			clickFilterBtn( filterName ){
				if( filterName == this.activeFilter ){
					this.activeFilter = ''
				}else{
					this.activeFilter = filterName
				}
				console.log( filterName )
			},
		},
		mounted() {
			// fetch events
			this.restHandler__fetch({
				action : 'GET',
				route : '/wp-json/mh/v1/ab__events',
				params : {
					range   : 'nowAndFuture',
					perPage : 99,
					preventCache : this._.random( 100, 999 ),
				},
				callback : (response) => {
					let results     = response.data.result
					let eventsYears = []

					// convert acf.date string to int
					this._.forEach( results, (item, i)=>{
						const dateInt = parseInt( item.acf.date )
						const dateStr = '' + item.acf.date

						results[i].acf.date = dateInt
						results[i].acf.dateYear  = parseInt( dateStr.substring(0, 4) )
						results[i].acf.dateMonth = parseInt( dateStr.substring(4, 6) )
					})

					// order by date
					results = this._.orderBy(results, ['acf.date', 'acf.time'], ['asc', 'asc'])

					// collect all eventDates
					this._.forEach( results, (item, i)=>{
						const dateStr = '' + item.acf.date

						eventsYears.push( dateStr.substring(0, 4) )
					})

					//
					let validFilters = []
					let eventsCats  = []
					// collect eventsCats
					this._.forEach( results, (item, i)=>{
						eventsCats.push( item.acf.category )
					})
					this._.forEach( this.validFilters, (item, i)=>{
						if( this._.includes( eventsCats, item ) ){
							this.eventsFilters.push( item )
						}
					})

					this.posts       = results
					this.eventsYears = this._.uniq( eventsYears )

					this.viewIsReady = true
				},
			})
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.EventsView {}

	.AbEvents {}
	.AbEvents__nav {
		display: flex;
		justify-content: space-between;

		border-bottom: 1px solid @swatches[blue];

		@media @mq[dt] {
			height: 72px;
		}
		@media @mq[md] {
			height: 72px;
		}
		@media @mq[sm] {
			height: 40px;
		}
	}
	.AbEvents__navFilter {
		overflow: hidden;
	}
	.AbEvents__navFilterBtn {
		.reset--button;

		//outline: 1px solid;
		padding: 0.35em 0.65em;

		&::after {
			display: inline-flex;
			padding-left: 0.35em;
			transform: scale(1.45);
			color: white;
			//content: "×"
		}
	}
	.AbEvents__navFilterBtn--isActive {
		color: white;
		background-color: @swatches[lighterBlue];
	}
	.AbEvents__groupTitle {
		[showBorders3] & { outline: 1px solid red; }
		[showBorders3] & > * { outline: 1px dotted red; }

		.grid;
		.grid--columnGap;
		.grid--setAreas;

		padding-top: 1.45em;
		padding-bottom: 0.85em;
		color: #BFCCCC;

		@media @mq[dt] {
			.grid--cols-12;
			grid-template-areas: ". . . a a a a a a a . .";

		}
		@media @mq[md] {
			.grid--cols-8;
			grid-template-areas: ". . a a a a a";
		}
		@media @mq[sm] {
			.grid--cols-4;
			grid-template-areas: "a a a a";
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
