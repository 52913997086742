<template>
	<div class="PageView view">

		<div class="minHeight minHeight--100">
			<AbMainHeader></AbMainHeader>
			<AbMainHeadline :text="page.title.rendered" v-if="page" />
			<AbContentModules :post="page"></AbContentModules>
		</div>

		<AbMainFooter></AbMainFooter>

		<!--
		<pre data-name="contentModules">{{contentModules}}</pre>
		<pre data-name="page">{{page}}</pre>
		-->
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'
	import ViewMixin from '@/views/View.mixin.js'

	import AbMainHeader from '@/components/AbMainHeader.vue'
	import AbMainFooter from '@/components/AbMainFooter.vue'
	import AbMainHeadline from '@/components/AbMainHeadline.vue'
	import AbContentModules from '@/components/AbContentModules.vue'

	export default {
		name: 'PageView',
		components: {
			AbMainHeader,
			AbMainFooter,
			AbMainHeadline,
			AbContentModules,
		},
		mixins: [ restHandler, ViewMixin ],
		directives: {},
		props: {},
		data() {
			return {
				page: null,
			}
		},
		computed: {},
		methods: {},
		mounted() {
			// fetch page
			this.restHandler__fetch({
				action : 'GET',
				route : '/wp-json/mh/v1/posts',
				params : {
					slug : this.$route.params.slug
				},
				callback : (response) => {
					this.page = response.data.result[0]

					this.viewIsReady = true
				},
			})
		},
	}
</script>

<style lang="less">
	//@import "../less/includes/grid.include.less";
	@import "../less/vars.less";

	.PageView {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
