<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="AbMainFooter contentModule" :class="elmClasses">
		<div class="AbMainFooter__inner contentModule__inner hSpace hSpace--page maxWidth maxWidth--page" :class="elmInnerClasses">
			<div class="AbMainFooter__pinkLinks font font--sans font--sizeSmall" v-if="footerLinks">
				<a 	class="AbMainFooter__pinkLinksLink"
					v-for="(link, i) in footerLinks" :key="i"
					:href="link.link"
				>{{link.text}}</a>
			</div>
			<nav class="AbMainFooter__sitemap font font--sans font--sizeMini" v-if="footerSitemapLinkGroups">
				<div class="AbMainFooter__sitemapGroup" v-for="(linkGroup, i) in footerSitemapLinkGroups" :key="i">
					<h4>{{linkGroup.title}}</h4>
					<ul class="AbMainFooter__sitemapList">
						<li class="font font--bold" v-for="(item, j) in linkGroup.links" :key="j">
							<a class="AbMainFooter__sitemapLink font font--bold" :href="item.link">{{item.text}}</a>
						</li>
					</ul>
					<br />
				</div>
			</nav>
			<div class="AbMainFooter__logo">
				<div class="AbMainFooter__logoImage"></div>
			</div>
			<!--
			<pre name="footerLinks">{{footerLinks}}</pre>
			<pre name="footerSitemapLinkGroups">{{footerSitemapLinkGroups}}</pre>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'AbMainFooter',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			elmInnerClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			footerSitemapLinkGroups(){
				return this._.get( this.app, 'acfOptions.footerSitemapLinkGroups')
			},
			footerLinks(){
				return this._.get( this.app, 'acfOptions.footerLinks')
			},
		},
		methods: {},
		created() {
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted() {},
		destroyed () {
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.AbMainFooter {
		overflow: hidden;

		@media @mq[dt] {
			margin-top: 3em;
			padding-top: 55px;
		}
		@media @mq[md] {
			margin-top: 3em;
			padding-top: 40px;
		}
		@media @mq[sm] {
			margin-top: 3em;
		}

		background-color: fade( @swatches[gold], 10 );
	}
	.AbMainFooter__pinkLinks {
		@media @mq[dt] {
			padding-bottom: 50px;
		}
		@media @mq[md] {
			padding-bottom: 40px;
		}
		@media @mq[sm] {
			display: none;
		}

		&Link {
			color: @swatches[pink];
			text-decoration: none;
			margin-right: 1.2em;
		}
	}
	.AbMainFooter__sitemap {
		display: block;
		color: @swatches[gold];

		@media @mq[dt] {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		@media @mq[md] {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		@media @mq[sm] {
			display: none;
		}

		&Group { // resp colum layout
			@media @mq[dt] {
				//width: calc( 100% / 6 );
			}
			@media @mq[md] {
				//width: calc( 100% / 3 );
			}
		}
		&Group { // gap between columns
			padding: 0 1em;

			&:first-child {
				margin-left: -1em;
			}
			&:last-child {
				margin-right: -1em;
			}
		}
		&List {
			.reset--list;
			margin: 0;
		}
		&Link {
			display: block;
			text-decoration: none;
			color: inherit;
		}
	}
	.AbMainFooter__logo {
		//background-color: fade( red, 20 );

		position: relative;
		overflow: hidden; // das logo wird unten abgeschnitten

		@media @mq[dt] {
			height: 200px;
		}
		@media @mq[md] {
			height: 200px;
		}

		&Image {
			position: absolute;
			top: 0; left: 0; right: 0;
			bottom: -30px;

			background-image: url(../assets/images/logo--gold.svg);
			background-repeat: no-repeat;
			background-position: left bottom;
			background-size: contain;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
