<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="AbEventAndNachlese" :class="elmClasses">
		<div class="AbEventAndNachlese__inner">
			<div class="AbEventAndNachlese__dateTime font font--sans font--bold font--sizeSmall">
				<span class="color color--lighterBlue" v-html="dateString"></span>
				<span class="color color--lighterBlue" v-if="post.acf.time"><br />{{post.acf.time}}&nbsp;Uhr <br /></span>
				<!--
				<span v-html="getDateString( post.acf.date )"></span><br />
				{{post.acf.dateYear}}<br />
				{{post.acf.dateMonth}}
				-->
			</div>
			<div class="AbEventAndNachlese__overTitle color color--lighterBlue font font--sans font--bold font--sizeSmall" v-html="getOverTitle( post )"></div>
			<div class="AbEventAndNachlese__body">
				<h4 class="AbEventAndNachlese__bodyTitle color color--blue font font--serif">
					<template v-if="post.type =='veranstaltung_post'">
						<router-link :to="{ name: 'EventView', params: { slug : post.slug } }">
							{{ post.title.rendered }} <br/>
							<span class="color color--pink" v-if="post.acf.titleAppendix">
								– {{post.acf.titleAppendix}}
							</span>
						</router-link>
					</template>
					<template v-if="post.type =='nachlese_post'">
						<router-link :to="{ name: 'NachleseViewDetail', params: { slug : post.slug } }" v-if="!isSingleView">
							{{post.title.rendered}}
						</router-link>
						<template v-else>{{post.title.rendered}}</template>
					</template>
				</h4>

				<AbContentModules :post="post"></AbContentModules>
				<!--
					<pre name="post">{{post}}</pre>
					<MhImage class="AbEventAndNachlese__bodyImage" :imageObject="post.acf.image" v-if="post.acf.image"></MhImage>
					<div class="AbEventAndNachlese__bodyText richText font font--sans font--sizeSmall" v-html="post.acf.text"></div>
				-->
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import AbContentModules from '@/components/AbContentModules.vue'

	export default {
		name: 'AbEventAndNachlese',
		components: {
			AbContentModules,
		},
		mixins: [],
		props: {
			post: [Object, Boolean],
			//text: [String],
		},
		data() {
			return {
				dayNames : [
					'Sonntag',
					'Montag',
					'Dienstag',
					'Mittwoch',
					'Donnerstag',
					'Freitag',
					'Samstag',
				],
				monthNames : [
					'Januar',
					'Februar',
					'März',
					'April',
					'Mai',
					'Juni',
					'Juli',
					'August',
					'September',
					'Oktober',
					'November',
					'Dezember',
				],
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			dateString(){
				let dateStr   = ''
				let returnStr = ''

				if( this.post.type == 'nachlese_post' ){
					const ts = Date.parse( this.post.date )
					const dateObj = new Date( ts );
					const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
					const date = ('0' + dateObj.getDate()).slice(-2);
					const year = dateObj.getFullYear();
					const shortDate = year + month + date;

					/*
					console.log('ts:', ts)
					console.log('shortDate:', shortDate)
					*/

					dateStr = shortDate
				}else{
					dateStr = this._.get(this.post, 'acf.date') + ''
				}

				//console.log('dateStr:', dateStr)

				// erwartet "20201230"
				if( dateStr ){
					const ymd       = dateStr
					const y         = ymd.substring(0, 4)
					const m         = ymd.substring(4, 6)
					const d         = ymd.substring(6, 8)
					const ymdStr    = y + '-' + m + '-' + d

					const dateObj   = new Date( ymdStr )
					const getDay    = dateObj.getDay()
					const dayName   = this.dayNames[getDay]
					const monthDay  = dateObj.getDate()
					const getMonth  = dateObj.getMonth()
					const monthName = this.monthNames[getMonth]

					/*
					console.log('---')
					console.log('y:', y)
					console.log('m:', m)
					console.log('d:', d)
					console.log('ymdStr:', ymdStr)
					console.log('dateObj:', dateObj)
					console.log('getDay :', getDay)
					console.log('dayName:', dayName)
					console.log('getMonth:', getMonth)
					console.log('monthName:', monthName)
					*/

					returnStr = dayName + ' ' + monthDay + '.&nbsp;' + monthName
					//returnStr = returnStr + ' ' + dateInt
				}

				if( this.post.type == 'nachlese_post' ){
					const ts = Date.parse( this.post.date )
					const dateObj = new Date(ts)
					const year = dateObj.getFullYear()

					returnStr = returnStr + '<br />' + year
				}
				return returnStr
			},
			isSingleView(){
				return this._.has(this.$route.params, 'slug')
			},
		},
		methods: {
			getOverTitle( post ){
				const postCat = this._.lowerCase( this._.get(post, 'acf.category') )
				let overTitle = ''

				if( postCat == 'sonstige' ){
					overTitle = this._.get(post, 'acf.customCategory')
				}else{
					overTitle = this._.get(post, 'acf.category')
				}

				return overTitle
			},
		},
		created() {
			console.log('isSingleView:', this.isSingleView)
		},
		mounted() {},
		destroyed () {
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";


	.AbEventAndNachlese {
		[showBorders3] & { outline: 1px solid red; }

		//padding-top: 0.85em;
		padding-bottom: 0.85em;
	}
	.AbEventAndNachlese__inner { // grid layout
		.grid;
		.grid--columnGap;
		.grid--setAreas;

		[showBorders3] & > * { outline: 1px dotted red; }

		@media @mq[dt] {
			.grid--cols-12;
			grid-template-areas: ". . . b b b b b b b . ."
								 "a a a c c c c c c c . .";
		}
		@media @mq[md] {
			.grid--cols-8;
			grid-template-areas: ". . b b b b b"
								 "a a c c c c c";
		}
		@media @mq[sm] {
			.grid--cols-4;
			grid-template-areas: "a a b b"
								 "c c c c";
		}
	}
	.AbEventAndNachlese__dateTime {
		@media @mq[dt] {
			padding-top: 0.6em;
			text-align: right;
		}
		@media @mq[md] {
			padding-top: 0.4em;
			text-align: right;
		}
		@media @mq[sm] {
			padding-top: 0em;
			text-align: left;
		}
	}
	.AbEventAndNachlese__overTitle {
		@media @mq[sm] {
			text-align: right;
		}
	}
	.AbEventAndNachlese__bodyTitle {
		padding-top: 0.25em;
		padding-bottom: 1em;

		a {
			text-decoration: none;
			color: inherit;
		}
	}
	.AbEventAndNachlese__bodyImage {
		margin-bottom: 1em;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
