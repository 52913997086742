<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="AbBookingForm contentModule" :class="elmClasses">
		<div class="AbBookingForm__inner contentModule__inner font hSpace hSpace--page maxWidth maxWidth--page" :class="elmInnerClasses">
			<form class="AbBookingForm__form" @submit="submitForm( $event )">
				<div class="richText font font--sizeSmall" v-if="textAbove" v-html="textAboveHtml"></div>

				<div class="AbBookingForm__formElms font font--sizeSmall"
					 :class="{'AbBookingForm__formElms--isSubmiting' : isSubmiting}">

					<BaseText class="AbBookingForm__formElm AbBookingForm__formElm--name font--bold"
						v-model="formData['Name']"
						:required="true"
						:placeholder="'Name*'"
					></BaseText>
					<BaseText class="AbBookingForm__formElm AbBookingForm__formElm--street font--bold"
						v-model="formData['Straße']"
						:required="true"
						:placeholder="'Straße, Hausnummer*'"
					></BaseText>
					<BaseText class="AbBookingForm__formElm AbBookingForm__formElm--zip font--bold"
						v-model="formData['PLZ']"
						:required="true"
						:placeholder="'PLZ*'"
					></BaseText>
					<BaseText class="AbBookingForm__formElm AbBookingForm__formElm--city font--bold"
						v-model="formData['Ort']"
						:required="true"
						:placeholder="'Ort*'"
					></BaseText>
					<BaseText class="AbBookingForm__formElm AbBookingForm__formElm--country font--bold"
						v-model="formData['Land']"
						:placeholder="'Land'"
					></BaseText>
					<BaseText class="AbBookingForm__formElm AbBookingForm__formElm--phone font--bold"
						v-model="formData['Telefon']"
						:required="true"
						:placeholder="'Telefon*'"
					></BaseText>
					<BaseText class="AbBookingForm__formElm AbBookingForm__formElm--mail font--bold"
						v-model="formData['Mail']"
						:required="true"
						:placeholder="'E-Mail*'"
					></BaseText>
					<BaseText class="AbBookingForm__formElm AbBookingForm__formElm--arrivalDate font--bold"
						v-model="formData['Anreise']"
						:required="true"
						:placeholder="'Anreise, TT.MM.JJJJ*'"
					></BaseText>
					<BaseText class="AbBookingForm__formElm AbBookingForm__formElm--departureDate font--bold"
						v-model="formData['Abreise']"
						:required="true"
						:placeholder="'Abreise, TT.MM.JJJJ*'"
					></BaseText>

					<!-- Übernachtung -->
					<div class="AbBookingForm__formElm">
						<br />
						<br />
						<strong>Übernachtung</strong>
						<br />
						<br />
					</div>
					<BaseText class="AbBookingForm__formElm AbBookingForm__formElm--guests font--bold"
						v-model="formData['Personenanzahl']"
						:placeholder="'Anzahl der Personen'"
					></BaseText>
					<BaseRadio class="AbBookingForm__formElm AbBookingForm__formElm--roomType font--bold"
						v-model="formData['Zimmerart']"
						:label="'Einzelzimmer'"
						:checked-value="'Einzelzimmer'"
						:name="'Zimmertyp'"
					></BaseRadio>
					<BaseRadio class="AbBookingForm__formElm AbBookingForm__formElm--roomType font--bold"
						v-model="formData['Zimmerart']"
						:label="'Zweibettzimmer'"
						:checked-value="'Zweibettzimmer'"
						:name="'Zimmertyp'"
					></BaseRadio>
					<BaseCheckbox class="AbBookingForm__formElm richText font--bold"
						v-model="formData['behindertengerecht']"
						:label="'behindertengerecht'"
						:checked-value="'ja'"
						:unchecked-value="'nein'"
					></BaseCheckbox>
					<BaseCheckbox class="AbBookingForm__formElm richText font--bold"
						v-model="formData['Frühstück']"
						:label="'Frühstück (Bitte informieren Sie uns über mögliche Unverträglichkeiten oder Allergien im Feld „Individuelle Wünsche“)'"
						:checked-value="'ja'"
						:unchecked-value="'nein'"
					></BaseCheckbox>
					<BaseRadio class="AbBookingForm__formElm richText font--bold"
						v-model="formData['Anreisezeit']"
						:label="'Anreisezeit während der <a href=/kontakt/>Öffnungszeit der Pforte</a>'"
						:checked-value="'während der Öffnungszeit der Pforte'"
						:name="'Anreisezeit'"
					></BaseRadio>
					<BaseRadio class="AbBookingForm__formElm richText font--bold"
						v-model="formData['Anreisezeit']"
						:label="'Anreise außerhalb der Öffnungszeit der Pforte'"
						:checked-value="'außerhalb der Öffnungszeit der Pforte'"
						:name="'Anreisezeit'"
					></BaseRadio>
					<!--
					<BaseText class="AbBookingForm__formElm AbBookingForm__formElm--kfz font--bold"
						v-model="formData['KFZ-Kennzeichen']"
						:placeholder="'Kfz-Kennzeichen bei Anreise mit dem PKW'"
					></BaseText>
					-->

					<!-- Seminar -->
					<div class="AbBookingForm__formElm richText">
						<br />
						<br />
						<strong>Seminare</strong>
						<br />
						<br />
					</div>
					<BaseText class="AbBookingForm__formElm AbBookingForm__formElm--arrivalDate richText font--bold"
						v-model="formData['Seminarbeginn']"
						:placeholder="'Seminarbeginn, TT.MM.JJJJ'"
					></BaseText>
					<BaseText class="AbBookingForm__formElm AbBookingForm__formElm--departureDate richText font--bold"
						v-model="formData['Seminarende']"
						:placeholder="'Seminarende, TT.MM.JJJJ'"
					></BaseText>
					<BaseCheckbox class="AbBookingForm__formElm richText font--bold"
						v-model="formData['Großer Seminarraum']"
						:label="'Großer Seminarraum bis 30 Teilnehmer'"
						:checked-value="'ja'"
						:unchecked-value="'nein'"
					></BaseCheckbox>
					<BaseCheckbox class="AbBookingForm__formElm richText font--bold"
						v-model="formData['Kleiner Seminarraum']"
						:label="'Kleiner Seminarraum bis 12 Teilnehmer'"
						:checked-value="'ja'"
						:unchecked-value="'nein'"
					></BaseCheckbox>
					<BaseCheckbox class="AbBookingForm__formElm richText font--bold"
						v-model="formData['Beamer']"
						:label="'Beamer'"
						:checked-value="'ja'"
						:unchecked-value="'nein'"
					></BaseCheckbox>
					<BaseCheckbox class="AbBookingForm__formElm richText font--bold"
						v-model="formData['Flipchart']"
						:label="'Flipchart'"
						:checked-value="'ja'"
						:unchecked-value="'nein'"
					></BaseCheckbox>
					<BaseCheckbox class="AbBookingForm__formElm richText font--bold"
						v-model="formData['Stehkaffee']"
						:label="'Stehkaffee'"
						:checked-value="'ja'"
						:unchecked-value="'nein'"
					></BaseCheckbox>
					<BaseCheckbox class="AbBookingForm__formElm richText font--bold"
						v-model="formData['Stehkaffee mit Kekssortiment']"
						:label="'Stehkaffee mit Kekssortiment'"
						:checked-value="'ja'"
						:unchecked-value="'nein'"
					></BaseCheckbox>
					<BaseCheckbox class="AbBookingForm__formElm richText font--bold"
						v-model="formData['Kaffee und Kuchen']"
						:label="'Kaffee und Kuchen'"
						:checked-value="'ja'"
						:unchecked-value="'nein'"
					></BaseCheckbox>

					<!-- Individuelle Wünsche -->
					<div class="AbBookingForm__formElm richText"></div>
					<BaseTextarea class="AbBookingForm__formElm AbBookingForm__formElm--wishes font--bold"
						v-model="formData['Individuelle Wünsche']"
						:placeholder="'Individuelle Wünsche'"
					></BaseTextarea>

					<!-- Datenschutzerklärung -->
					<div class="AbBookingForm__formElm richText"></div>
					<BaseCheckbox class="AbBookingForm__formElm richText font--bold"
						v-model="formData['Datenschutzerklärung gelesen']"
						:label="privacyPolicyCheckboxLabel"
						:checked-value="'ja'"
						:unchecked-value="'nein'"
						:required="true"
					></BaseCheckbox>

				</div>

				<!-- submit button -->
				<button class="AbBookingForm__submitBtn button font--serif font--sizeSmall background--blue color--white">absenden</button><br />

				<!-- success message -->
				<div class="AbBookingForm__successMessage font font--sizeSmall"
					 :class="{'AbBookingForm__successMessage--isVisible' : showSuccessMessage}">
					<a class="AbBookingForm__successMessageCloseBtn" @click="showSuccessMessage = false"></a>
					<div class="AbMessageBox background background--lightGold">
						<div class="AbMessageBox__text font font--sans font--colorWhite font--sizeSmall richText">
							Vielen Dank für Ihre Anfrage, Ihre Daten wurden erfolgreich übermittelt.<br/>
						</div>
					</div>
				</div>

				<!--
				<div class="font font--sizeSmall">
					<mark>• Mail send + live testing</mark><br />
				</div>
				<pre name="formData">{{formData}}</pre>
				-->
			</form>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'

	import BaseText from '/Users/Mario/Dropbox/htdocs/2020-09-17__MhForm/vue-cli-dev/src/components/MhForm/v1/BaseText.vue'
	import BaseTextarea from '/Users/Mario/Dropbox/htdocs/2020-09-17__MhForm/vue-cli-dev/src/components/MhForm/v1/BaseTextarea.vue'
	import BaseCheckbox from '/Users/Mario/Dropbox/htdocs/2020-09-17__MhForm/vue-cli-dev/src/components/MhForm/v1/BaseCheckbox.vue'
	import BaseRadio from '/Users/Mario/Dropbox/htdocs/2020-09-17__MhForm/vue-cli-dev/src/components/MhForm/v1/BaseRadio.vue'
	import BaseSelect from '/Users/Mario/Dropbox/htdocs/2020-09-17__MhForm/vue-cli-dev/src/components/MhForm/v1/BaseSelect.vue'
	import BaseButton from '/Users/Mario/Dropbox/htdocs/2020-09-17__MhForm/vue-cli-dev/src/components/MhForm/v1/BaseButton.vue'

	export default {
		name: 'AbBookingForm',
		components: {
			BaseText,
			BaseTextarea,
			BaseCheckbox,
			BaseRadio,
			BaseSelect,
			BaseButton,
		},
		mixins: [
			restHandler,
		],
		props: {
			textAbove: [String, Boolean],
		},
		data() {
			return {
				formData : {
					'Name'    : '',
					'Straße'  : '',
					'PLZ'     : '',
					'Ort'     : '',
					'Land'    : '',
					'Telefon' : '',
					'Mail'    : '',
					'Anreise' : '',
					'Abreise' : '',

					'Personenanzahl'     : '',
					'Zimmerart'          : '',
					'behindertengerecht' : '',
					'Frühstück'          : '',
					'Anreisezeit'        : '',
					//'KFZ-Kennzeichen'    : '',

					'Seminarbeginn'                : '',
					'Seminarende'                  : '',
					'Großer Seminarraum'           : '',
					'Kleiner Seminarraum'          : '',
					'Beamer'                       : '',
					'Flipchart'                    : '',
					'Stehkaffee'                   : '',
					'Stehkaffee mit Kekssortiment' : '',
					'Kaffee und Kuchen'            : '',
					'Individuelle Wünsche'         : '',

					'Datenschutzerklärung gelesen'  : '',
				},
				isSubmiting : false,
				showSuccessMessage : false,
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			elmInnerClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			textAboveHtml(){
				const str = this._.trim( this.textAbove )
				const breakTag = '<br />'
				const htmlStr = String(str).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')

				return htmlStr + '<br/><br/>'
			},
			privacyPolicyLink(){
				return this.app.acfOptions.privacyPolicyPage
			},
			privacyPolicyCheckboxLabel(){  // computed value is needed to add the dynamic link
				return 'Ich habe die <a href="'+this.privacyPolicyLink+'">Datenschutzerklärung</a> gelesen und stimme zu.'
			},
		},
		methods: {
			submitForm( e ){
				// allow only one submit in a row
				if( this.isSubmiting ){
					e.preventDefault()
				}

				this.isSubmiting = true

				// send formData to rest endpoint for send mail
				this.restHandler__fetch({
					action : 'POST',
					route : '/wp-json/mh/v1/ab__contactForm',
					data : {
						formData : this.formData,
					},
					callback : (response) => {
						console.log('response', response)

						setTimeout(()=>{
							//this.resetForm()
							e.target.reset()

							this.showSuccessMessage = true
							this.isSubmiting = false
						}, 5000)

						setTimeout(()=>{
							// kein automatisches ausblenden,
							// die messageBox hat jetzt einen close-button
							// this.showSuccessMessage = false
						}, 4000)
					},
				})

				e.preventDefault()
			},
			// reset every formData value back to initial empty string ''
			// 2020-11-04  ist raus, weil damit im ff die required field als
			// 			   invalide gekennzeichnet wurden. ich löse das jetzt via e.target.reset()
			/*
			resetForm(){
				this._.forEach( this.formData, (value, key)=>{
					this.formData[key] = ''
				})
			},
			*/
		},
		created() {},
		mounted() {},
		destroyed () {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.AbBookingForm {
		&__inner {
			.grid;
			.grid--columnGap;

			@media @mq[dt] {
				.grid--cols-12;
			}
			@media @mq[md] {
				.grid--cols-8;
			}
			@media @mq[sm] {
				.grid--cols-4;
			}
		}
		&__form {
			//background-color: fade( red, 20 );

			@media @mq[dt] {
				grid-column-start: 3;
				grid-column-end: 11;
			}
			@media @mq[md] {
				grid-column-start: 2;
				grid-column-end: 8;
			}
			@media @mq[sm] {
				grid-column-start: 1;
				grid-column-end: 5;
			}

		}

		&__formElms {
			.grid;
			.grid--columnGap;

			transition: all 0.2s ease;

			@media @mq[dt] {
				.grid--cols-8;
			}
			@media @mq[md] {
				.grid--cols-6;
			}
			@media @mq[sm] {
				.grid--cols-4;
			}

			&--isSubmiting {
				opacity: 0.5;
			}
		}
		&__formElm { // col span for Base* form elements
			//outline: 1px solid red;

			@media @mq[dt] {
				grid-column-start: 1;
				grid-column-end: 9;
			}
			@media @mq[md] {
				grid-column-start: 1;
				grid-column-end: 7;
			}
			@media @mq[sm] {
				grid-column-start: 1;
				grid-column-end: 5;
			}

			&--zip {
				@media @mq[dt] { grid-column: span 3; }
				@media @mq[md] { grid-column: span 2; }
				@media @mq[sm] { grid-column: span 3; }
			}
			&--city {
				@media @mq[dt] { grid-column: span 5; }
				@media @mq[md] { grid-column: span 4; }
			}
			&--country {
				@media @mq[dt] { grid-column: span 5; }
				@media @mq[md] { grid-column: span 5; }
				@media @mq[sm] { grid-column: span 4; }
			}
			&--phone {
				@media @mq[dt] { grid-column: span 4; }
				@media @mq[md] { grid-column: span 3; }
				@media @mq[sm] { grid-column: span 4; }
			}
			&--mail {
				@media @mq[dt] { grid-column: span 4; }
				@media @mq[md] { grid-column: span 3; }
				@media @mq[sm] { grid-column: span 4; }
			}

			&--arrivalDate {
				@media @mq[dt] { grid-column: span 4; }
				@media @mq[md] { grid-column: span 3; }
				@media @mq[sm] { grid-column: span 4; }
			}
			&--departureDate {
				@media @mq[dt] { grid-column: span 4; }
				@media @mq[md] { grid-column: span 3; }
				@media @mq[sm] { grid-column: span 4; }
			}
			&--guests {
				@media @mq[dt] { grid-column: span 4; }
				@media @mq[md] { grid-column: span 4; }
				@media @mq[sm] { grid-column: span 4; }
			}
			&--roomType {
				display: flex;
				align-items: center;

				@media @mq[dt] { grid-column: span 2; }
				@media @mq[md] { grid-column: span 3; }
				@media @mq[sm] { grid-column: span 4; }
			}
		}
		&__submitBtn {
			margin: 1.5em 0;
		}

		.BaseText,
		.AbBookingForm__formElm--wishes {
			margin-bottom: 1em;
		}
		.BaseRadio,
		.BaseCheckbox {
			margin-bottom: 0.5em;
		}
		.AbBookingForm__formElm--kfz {
			margin-top: 0.5em;
		}
		.AbBookingForm__formElm--wishes {
			margin-top: 1em;
		}

		.BaseText__inner {
			//height: 3em;
			//border-color: transparent
		}
		.BaseCheckbox__label a {
			margin-top: 1px;
			margin-left: 0.3em;
			margin-right: 0.3em;
		}


	}
	.AbBookingForm { // custom form elm styling
		.BaseRadio,
		.BaseCheckbox {
			--activeBackgroundColor : @swatches[blue];
		}
		.BaseText {
			--height : 3em;
			--border : 1px solid transparent;
		}
		.BaseTextarea {
			--height : 10em;
			--border : 1px solid transparent;
		}
	}
	.AbBookingForm__successMessage { // visible after submit
		position: relative;
		transition: all 0.2s ease;
		opacity: 0;

		&--isVisible {
			opacity: 1;
		}

		&CloseBtn {
			position: absolute;
			top: 0.5em; right: 0.5em;
			display: block;
			height: 1em; width: 1em;
			background-image: url('../assets/images/icon--cross.svg');
			background-size: contain;
		}

		.AbMessageBox {
			padding-right: 3em;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
