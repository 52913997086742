<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<AbSlider></AbSlider>

	2019-06-08	init

-->

<template>
	<div class="AbSlider contentModule" :class="elmClasses">
		<div class="AbSlider__inner contentModule__inner font maxWidth maxWidth--page" :class="elmInnerClasses">
			<div class="AbSlider__wrapper">

				<!--
				<pre name="currentSlideIndex">{{currentSlideIndex}}</pre>
				<pre name="currentSlideText">{{currentSlideText}}</pre>
				-->

				<VueperSlides
					class="AbSlider__slider"
					ref="myVueperSlides"
					:infinite="false"
					:disableArrowsOnEdges="false"
					:transitionSpeed="500"
					:touchable="true"
					:slideRatio="sliderRatio"
					:draggingDistance="70"
					:bullets="false"
					:bullets-outside="true"
					:arrows="true"
					:autoplay="false"
					:speed="4000"
					:pauseOnHover="false"
					:fade="false"
					@before-slide="onBeforeSlide"
				>
					<VueperSlide v-for="(slide, i) in slides" :key="i">
						<div class="AbSlider__slideContent" slot="slideContent">
							<!--
							<pre name="slide">{{slide}}</pre>
							-->
							<MhImage
								class="AbSlider__sliderImage"
								:mode="'cover'"
								:imageObject="slide.image"
							></MhImage>
						</div>
					</VueperSlide>
				</VueperSlides>

				<MhSliderDots class="MhSliderDots--customStyling"
					:count="slides.length"
					:activeIndex="currentSlideIndex"
					@goToSlide="$refs.myVueperSlides.goToSlide( $event )"
				></MhSliderDots>

				<div>
					<AbRichText
						:text="currentSlideText"
						:fontSize="'small'"
					></AbRichText>
				</div>

				<!--
				<pre name="$props">{{$props}}</pre>
				<pre name="$attrs">{{$attrs}}</pre>
				-->
			</div>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import VueperSlides from '@/components/VueperSlides/VueperSlides.vue'
	import VueperSlide from '@/components/VueperSlides/VueperSlide.vue'

	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'
	import MhSliderDots from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhSliderDots/v3/MhSliderDots.vue'
	import AbRichText from '@/components/AbRichText.vue'

	export default {
		name: 'AbSlider',
		components: {
			VueperSlides,
			VueperSlide,
			MhImage,
			MhSliderDots,
			AbRichText,
		},
		mixins: [],
		props: {
			postType: {
				type: [String, Boolean],
				default: '',
			},
			slides: {
				type     : [Array, Boolean],
				default  : ()=>{ return [] },
				required : false,
			},
			isFullwidth: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
		},
		data() {
			return {
				currentSlideIndex : 0,
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			elmInnerClasses(){
				let classes = []

				if( this.postType == 'page' ){
					classes.push('hSpace hSpace--page')

					if( this.isFullwidth ){
						classes.push('layout layout--▨▨▨▨▨▨▨▨▨▨▨▨')
					}
					else{
						classes.push('layout layout--□□▨▨▨▨▨▨▨▨□□')
					}
				}else{
					if( this.isFullwidth ){
						classes.push('layout layout--▨▨▨▨▨□')
					}
					else{
						classes.push('layout layout--▨▨▨▨□')
					}
				}

				return classes
			},
			currentSlideText(){
				return this.slides[ this.currentSlideIndex ].text
			},
			sliderRatio(){ // slider ratio wird vom ratio des ersten bildes im ersten slide bestimmt
				const height = this.slides[0].image.height
				const width = this.slides[0].image.width
				return height / width
			},
		},
		methods: {
			onBeforeSlide( e, slideObj ){
				const currentSlide = slideObj.currentSlide
				const nextSlide = slideObj.nextSlide

				console.log('onBeforeSlide', e, slideObj)
				console.log('currentSlide', currentSlide)
				console.log('nextSlide', nextSlide)

				this.currentSlideIndex = nextSlide.index
			},
		},
		created() {
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted() {},
		destroyed () {
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.AbSlider {
		.vueperSlides__track { // just so show something on overscroll
			background-color: fade( black, 10 );
		}

		.vueperSlides__arrows {}
		.vueperSlides__arrow {
			transition: all 0.25s ease;
			position: absolute;
			top: calc( 50% - 0.75em );
			width: 1.25em; height: 1.25em;

			display: flex;
			justify-content: center;
			align-items: center;

			color: @swatches[blue];
			opacity: 0.5;
			background-color: white;
			padding: 15px 0;
			//border-top: 5px solid white;
			//border-bottom: 5px solid white;
		}
		.vueperSlides__inner:hover .vueperSlides__arrow {
			opacity: 1;
		}
		.vueperSlides__arrow--prev {
			left: 0.15em;
		}
		.vueperSlides__arrow--next {
			right: 0.15em;
		}
		.vueperSlides--atStart .vueperSlides__arrow--prev {
			display: none;
		}
		.vueperSlides--atEnd .vueperSlides__arrow--next {
			display: none;
		}

		.MhImage {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
		}

		.MhSliderDots--customStyling {
			--dot-height: 0.25em;
			--dot-width: 2em;
			--dot-borderRadius: 0%;
			--dot-margin: 0.75em 0.25em;
			--dot--activeColor: @swatches[blue];
			--dot--inactiveColor: #BFCCCC;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
