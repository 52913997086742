<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="AbHomeGallery contentModule" :class="elmClasses">
		<div class="AbHomeGallery__inner contentModule__inner" :class="elmInnerClasses" v-if="images">
			<VueperSlides
				class="AbHomeGallery__slider"
				:infinite="true"
				:disableArrowsOnEdges="false"
				:transitionSpeed="transitionSpeed"
				:touchable="true"
				:slideRatio="1"
				:draggingDistance="70"
				:bullets="true"
				:bullets-outside="true"
				:arrows="false"
				:autoplay="true"
				:speed="4000"
				:pauseOnHover="false"
				:fade="true"
			>
				<VueperSlide v-for="(image, i) in images" :key="i">
					<div class="AbHomeGallery__slideContent" slot="slideContent">
						<MhImage
							class="AbHomeGallery__sliderImage"
							:mode="'cover'"
							:imageObject="image"
						></MhImage>
					</div>
				</VueperSlide>
			</VueperSlides>
			<div class="AbHomeGallery__colorOverlay"></div>
			<div class="AbHomeGallery__logo">
				<div class="AbHomeGallery__logoImage maxWidth maxWidth--page"></div>
			</div>


			<!--
			<pre name="images">{{images}}</pre>
			-->
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import VueperSlides from '@/components/VueperSlides/VueperSlides.vue'
	import VueperSlide from '@/components/VueperSlides/VueperSlide.vue'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'

	export default {
		name: 'AbHomeGallery',
		components: {
			VueperSlides,
			VueperSlide,
			MhImage,
		},
		mixins: [],
		props: {
			images: [Array, Boolean],
		},
		data() {
			return {
				transitionSpeed : 500,
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			elmInnerClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
		destroyed () {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	@slider : {
		dtRatio : 32%;
		mdRatio : 52%;
		smRatio : 90%;
	}

	.AbHomeGallery {
		position: relative;

		.vueperSlides__inner {
			pointer-events: none;
		}
		.vueperSlides__parallax-wrapper { // slider ratio
			@media @mq[dt] {
				padding-bottom: @slider[dtRatio] !important;
			}
			@media @mq[md] {
				padding-bottom: @slider[mdRatio] !important;
			}
			@media @mq[sm] {
				padding-bottom: @slider[smRatio] !important;
			}
		}
		.vueperSlide {
			position: relative;
		}

		.vueperSlides--fade {
			//outline: 2px solid red;

			.vueperSlide--clone {
				display: none;
			}
			.vueperSlide {
				position: absolute;
				top: 0; left: 0;
				right: 0; bottom: 0;
			}
		}

		.vueperSlides__bullets {
			//background-color: fade( red, 20 );

			position: relative;
			padding: 0.5em 0;
			z-index: 3;
			background-color: white;
		}
		.vueperSlides__bullet {
			height: 8px;
			width: 70px;
			margin: 0 0.25em;

			transition: all 0.2s ease;
			overflow: hidden;
			background-color: #BFCCCC;
			color: transparent;

			&--active {
				background-color: @swatches[blue];
			}
		}

		@media @mq[sm] {
			.vueperSlides__bullets {
				padding-left: 1.5vw;
				padding-right: 1.5vw;
			}
			.vueperSlides__bullet {
				margin: 0 1.5vw;
			}
		}
	}

	.AbHomeGallery__slider {
		position: relative;
		//.outline( red );
		//margin-bottom: -@slider[dtRatio];
	}
	.AbHomeGallery__sliderImage {
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
	}

	.AbHomeGallery__colorOverlay {
		position: absolute;
		left: 0; right: 0;
		top: 0; bottom: 0;
		pointer-events: none;
		z-index: 1;

		background-color: fade( #2B2E34, 15 );

		@media @mq[dt] {
			display: none;
		}
		@media @mq[md] {
			display: none;
		}
		@media @mq[sm] {
			display: block;
		}
	}
	.AbHomeGallery__logo {
		//background-color: fade( yellow, 50 );

		position: absolute;
		left: 0; right: 0;
		bottom: 0.5em;
		height: 50%;
		max-height: 400px;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		pointer-events: none;
		z-index: 2;

		@media @mq[dt] {
			height: 75%;
		}
		@media @mq[md] {
			height: 75%;
		}
		@media @mq[sm] {
			height: 50%;
		}
	}
	.AbHomeGallery__logoImage {
		//background-color: fade( red, 25 );

		position: relative;
		flex-grow: 1;
		height: 100%;

		background-image: url(../assets/images/logo--short-white.svg);
		background-repeat: no-repeat;
		background-position: right 24px bottom;
		background-size: contain;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
