<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2020-07-27	init

-->

<template>
	<div class="MhCookieNotice" :class="elmClasses">
		<div class="MhCookieNotice__inner font font--sizeSmall hSpace hSpace--colGap">
			<div class="MhCookieNotice__textWrapper">
				Wir setzen auf unserer Websiten nur technisch notwendige Cookies ein.
				Allgemeine Informationen zu unserem Datenschutz können Sie
				<router-link
					class="MhCookieNotice__privacyLink"
					:to="'/datenschutz/'"
				>hier nachlesen</router-link>.
			</div>
			<div class="MhCookieNotice__btnWrapper">
				<button class="MhCookieNotice__btn" role="button" @click="clickOK()">
					OK
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'MhCookieNotice',
		components: {},
		mixins: [],
		props: {},
		data() {
			return {
				isConfirmed : null,
				isHidden    : true,
				storageVar  : 'MhCookieNotice: isConfirmed'
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.isHidden ) classes.push('MhCookieNotice--isHidden')

				return classes
			},
		},
		methods: {
			clickOK() {
				localStorage.setItem( this.storageVar, 1 )
				this.isConfirmed = true
				this.isHidden = true
			},
		},
		created() {},
		mounted() {
			let isHidden = false

			if( parseInt( localStorage.getItem( this.storageVar ) ) === 1 ) {
				isHidden = true
			}else{
				isHidden = false
			}

			setTimeout(()=>{
				this.isHidden = isHidden
			}, 500)
		},
	}
</script>

<style lang="less">
	@import "../../less/vars.less";
	@import (reference) "../../less/mixins.less";
	@import (reference) "../../less/atoms.less";

	@MhCookieNotice : {
		color: white;
		background: #2B2E34;
		padding: 0.75em;;
	}

	.MhCookieNotice {
		position: fixed;
		z-index: 100;

		@media @mq[dt] { right: 18px; bottom: 18px; width: 450px; }
		@media @mq[md] { right: 18px; bottom: 18px; width: 450px; }
		@media @mq[sm] { right: 3vw; left: 3vw; bottom: 3vw; width: auto;  }

		transition: all 0.2s ease;
		transform: translateY( 0% );
		background: @MhCookieNotice[background];
		color: @MhCookieNotice[color];

		&--isHidden {
			transform: translateY( 120% );
			pointer-events: none;
		}

		&__inner {
			//& > * { outline: 1px solid red; }

			display: flex;
			justify-content: space-between;
			padding: @MhCookieNotice[padding];
		}
	}
	.MhCookieNotice__textWrapper {
		padding-right: 1em;

		a {
			transition: all 0.1s ease;
			color: inherit;
			text-decoration: none;
			border-bottom: 1px solid fade( white, 25 );
			white-space: nowrap;
		}
		a:hover {
			border-bottom-color: currentColor;
		}
	}
	.MhCookieNotice__btnWrapper {}
	.MhCookieNotice__btn {
		.reset--button;

		color: transparent;
		transition: all 0.1s ease;

		opacity: 0.75;
		&:hover { opacity: 1; }

		background-image: url(../../assets/images/icon--cross.svg);
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: contain;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
