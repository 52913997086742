/*

	Helper mixin extends a view component with some useful methods

	Emits 'ViewEvent' with payload
		{
			fromComponent : name of the previous view component, e.g. PageView
			eventName     : beforeDestroy | beforeMount | mounted
		}

	Exposes $data.viewIsReady boolean which is false by default
		if set to true it $emits 'ViewEvent' with eventName 'ready'
		usage example, set true if view content is fetched:
			mounted() {
				this.fetchPost(()=>{
					this.viewIsReady = true
				})
			}

	Exposes $methods.viewEventHandler( payload ) function

	Handles scroll position between navigation
	to get this working you need to add to your router.js:

		// Handle scrollRestoration manual, stops browser from doing it
		if('scrollRestoration' in window.history) {
			window.history.scrollRestoration = 'manual';
		}

	2020-10-08	erweiterung: wenn in der url ein #hash war, wird zu dem element gesprungen sobald der view ready = voll geladen ist
	2020-09-29	erweiterung und clean up:
				- scrollRestoration nur noch wenn es auch eine popstate navigation (browser back or forward button) nav war
	2020-03-25	erweiterung
	2019-09-28	init

*/
import EventBus from '@/helper/EventBus.js'

export default {
	metaInfo() {
		return {
			title: this.title,
			titleTemplate: this.titleTemplate,
			meta: [],
			link: [],
		}
	},
	watch: {
		viewIsReady(to, from) {
			if (to) {
				EventBus.$emit('ViewEvent', {
					fromComponent: this.$options.name,
					eventName: 'ready',
				})
			}
		},
	},
	data() {
		return {
			title: '',
			titleTemplate: (titleChunk) => {
				// re-replace ampersand '&#038;' back to '&'' in title
				const cleanTitleChunk = titleChunk.replace('&#038;', '&')
				// If undefined or blank then we don't need the hyphen
				return cleanTitleChunk ? `${cleanTitleChunk} – digi.camp` : 'digi.camp';
			},

			viewIsReady: false,
			wasPopstateNav: false,
			scrollRestoration: {
				listen: true,
				doRestore: false,
			},
		}
	},
	computed: {},
	methods: {
		viewEventHandler(payload, doLog = false) {
			const currentComponent = this.$options.name

			if (doLog) {
				console.groupCollapsed('%c' + currentComponent + ': $on("ViewEvent")', 'background-color: yellow', '•', payload.fromComponent, payload.eventName)
				console.log('payload:', payload)
				console.groupEnd()
			}
		},
		scrollEventHandler(e, doLog = false) {
			const doCaptureScroll = true // to disable this feature
			const scrollY = window.pageYOffset
			const currentComponent = this.$options.name
			const urlPath = this.$route.path

			if (this.scrollRestoration.listen && doCaptureScroll) {

				if (doLog) {
					console.log(currentComponent + ': scrollEventHandler() • set sessionStorage.scrollY:', scrollY)
					console.log('urlPath:', urlPath)
				}

				sessionStorage.setItem(urlPath + ' scrollY', scrollY)
			}
		},
		scrollRestorationHandler(payload, doLog = false) {
			const currentComponent = this.$options.name
			const fromComponent = payload.fromComponent
			const eventName = payload.eventName
			const urlPath = this.$route.path
			const scrollY = sessionStorage.getItem(urlPath + ' scrollY') ? parseInt(sessionStorage.getItem(urlPath + ' scrollY')) : 0

			if (eventName == 'beforeCreate') {
				if (doLog) console.log(currentComponent + ': beforeMount() • set window.scrollTo(0, 0)')
				this.scrollRestoration.listen = false
				window.scrollTo(0, 0)
			}
			if (eventName == 'beforeMount') {
				/*
				if( doLog ) console.log(currentComponent + ': beforeMount() • set window.scrollTo(0, 0)')
				this.scrollRestoration.listen = false
				window.scrollTo(0, 0)
				*/
			}
			//if( eventName == 'mounted' ){}
			if (eventName == 'ready') {
				if (doLog) console.log(currentComponent + ': ready() • scrollTo() sessionStorage.scrollY:', scrollY)

				if (this.scrollRestoration.doRestore) {
					this.$nextTick(() => {
						window.scrollTo(0, scrollY)
					})
				} else {
					this.$nextTick(() => {
						window.scrollTo(0, 0)
					})
				}

				this.scrollRestoration.listen = true

				setTimeout(() => {
					const scrollY = window.pageYOffset
					if (doLog) console.log(currentComponent + ': delayed ready() • window.pageYOffset:', scrollY)
				}, 1000)
			}
			//if( eventName == 'beforeDestroy' ){}
			//if( eventName == 'destroyed' ){}
		},
		popstateHandler(e) { // saves in sessionStorage if it was popstate nav
			//console.log("location: " + document.location + ", state: " + JSON.stringify(event.state))
			//console.log('wasPopstateNav:', 1)
			sessionStorage.setItem('wasPopstateNav', 1)
		},
		onReadyEventHandler(e) { // scrolls to #hash if view is ready = fully loaded
			const eventName = e.eventName
			const hash = this._.trim(window.location.hash, '#')
			const wasPopstateNav = this.wasPopstateNav

			if (eventName === 'ready' && hash && !wasPopstateNav) {
				// short delay is needed to let it render ($nextTick was not enough)
				setTimeout(() => {
					const $el = this.$el
					const targetElm = $el.querySelector('#' + hash)

					if (targetElm) {
						targetElm.scrollIntoView({
							block: 'start',
							behavior: 'auto'
						})
					}

					console.log('this:', this)
					console.log('eventName:', eventName)
					console.log('hash:', hash)
					console.log('$el:', $el)
					console.log('wasPopstateNav:', wasPopstateNav)
					console.log('targetElm:', targetElm)
					console.log('')
					/*
					*/
				}, 50)
			}
		},
	},
	beforeCreate() {
		EventBus.$emit('ViewEvent', {
			fromComponent: this.$options.name,
			eventName: 'beforeCreate',
		})
	},
	created() {
		EventBus.$on('ViewEvent', this.viewEventHandler)

		EventBus.$emit('ViewEvent', {
			fromComponent: this.$options.name,
			eventName: 'created',
		})

		window.addEventListener('popstate', this.popstateHandler)
		window.addEventListener('scroll', this.scrollEventHandler)
	},
	beforeMount() {
		EventBus.$emit('ViewEvent', {
			fromComponent: this.$options.name,
			eventName: 'beforeMount',
		})
	},
	mounted() {
		EventBus.$on('ViewEvent', this.scrollRestorationHandler)

		EventBus.$emit('ViewEvent', {
			fromComponent: this.$options.name,
			eventName: 'mounted',
		})

		const wasPopstateNav = sessionStorage.getItem('wasPopstateNav')
		if (wasPopstateNav) {
			this.scrollRestoration.doRestore = true
			this.wasPopstateNav = true
		} else {
			this.scrollRestoration.doRestore = false
			this.wasPopstateNav = false
		}
		sessionStorage.removeItem('wasPopstateNav')
		//console.log('ViewEvent: ready: wasPopstateNav:', wasPopstateNav)

		EventBus.$on('ViewEvent', this.onReadyEventHandler)
	},
	beforeDestroy() {
		//const scrollY = window.pageYOffset
		//console.log('beforeDestroy: scrollY: ', scrollY )

		EventBus.$emit('ViewEvent', {
			fromComponent: this.$options.name,
			eventName: 'beforeDestroy',
		})

		EventBus.$off('ViewEvent', this.viewEventHandler)
		EventBus.$off('ViewEvent', this.scrollRestorationHandler)

		window.removeEventListener('scroll', this.scrollEventHandler)
		//window.removeEventListener('popstate', this.popstateHandler )
	},
	destroyed() {
		EventBus.$emit('ViewEvent', {
			fromComponent: this.$options.name,
			eventName: 'destroyed',
		})

		EventBus.$off('ViewEvent', this.onReadyEventHandler)

		// needs short delay before removing popstateHandler
		// it wont trigger the event without this delay
		setTimeout(() => {
			window.removeEventListener('popstate', this.popstateHandler)
		}, 50)
	},
}
