<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="AbTextAndImage contentModule" :class="elmClasses">
		<div class="AbTextAndImage__inner contentModule__inner layout font hSpace hSpace--page maxWidth maxWidth--page" :class="elmInnerClasses">
			<div class="AbTextAndImage__textWrapper richText" v-html="text"></div>
			<div class="AbTextAndImage__imageWrapper">
				<MhImage :imageObject="image"></MhImage>
			</div>
			<!--
			<pre name="$options.propsData">{{$options.propsData}}</pre>
			AbTextAndImage
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import EventBus from '@/helper/EventBus.js'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'

	export default {
		name: 'AbTextAndImage',
		components: {
			MhImage,
		},
		mixins: [],
		props: {
			text: {
				type: [String, Boolean],
				default: '',
			},
			layout: {
				type: [String, Boolean],
				default: '▨▨▨▨▨▨▨□☰☰☰☰',
			},
			image: {
				type: [Object, Boolean],
				default: false,
			},
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
			elmInnerClasses(){
				let classes = []

				classes.push('font--sans')
				classes.push('font--sizeSmall')
				classes.push('font--colorGrey')
				classes.push('layout--' + this.layout)

				return classes
			},
		},
		methods: {},
		created() {
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted() {},
		destroyed () {
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.AbTextAndImage {}
	.AbTextAndImage__textWrapper {
		@media @mq[md] { padding-bottom: 1.5em; }
	}
	.AbTextAndImage__imageWrapper {
		& > * { background-color: fade( black, 20 ); }

		@media @mq[md] { padding-bottom: 1.5em; }
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
