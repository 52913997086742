<!-- 

	Die Componente dient als Transparent-Wrapper für alle Button Elemente
	
	Props:
		-
	
	Markup:
		<BaseButton></BaseButton>
	
	Changelog
		2020-11-11	überarbeitet: css vars for styling
		2020-08-08	vereinfacht
		2019-08-06	improvement: added new prop "tag" to render button as link or button aso. 
		2019-07-09	init

-->

<template>
	<div 
		class="BaseButton" 
		:class="elmClasses"
		>
		<component :is="tag" class="BaseButton__inner" v-html="label" :disabled="disabled"></component>
		<div class="BaseButton__border"></div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import { EventBus } from '@/event-bus.js'

	export default {
		name: 'BaseButton',
		components: {},
		mixins: [],
		//props: ['type', 'label'],
		props: {
			label: {
				type: [String, Number],
				default: ''
			},
			tag: {
				type: String,
				default: 'button'
			},
			disabled: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			elmClasses(){
				 const type = this.type
				 let classes = []
				 
				 if( type ) classes.push('BaseButton--' + this.type)
				 if( type && type == 'outlined'){
					  //classes.push('BaseButton--' + this.type)
				 }
				 if( type && type == 'solid'){
					 //classes.push('BaseButton--' + this.type)
				 }
				 if( this.disabled ){
					 classes.push('BaseButton--isDisabled')
				 }
				 
				 return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import (reference) "./vars.less";
	@import (reference) "./mixins.less";

	.BaseButton {	// css vars
		--height                : @buttonElm[height];
		--borderRadius          : @buttonElm[borderRadius];
		--paddingLeft           : @buttonElm[paddingLeft];
		--paddingRight          : @buttonElm[paddingRight];
		--labelPadding          : @buttonElm[labelPadding];
		--border                : @buttonElm[border];
		--hoverBorder           : @buttonElm[hoverBorder];
		--focusBorder           : @buttonElm[focusBorder];
		--activeBackgroundColor : @buttonElm[activeBackgroundColor];
		--activeForegroundColor : @buttonElm[activeForegroundColor];
		--hoverBackgroundColor  : @buttonElm[hoverBackgroundColor];
		--transition            : @buttonElm[transition];
	}
	
	.BaseButton {
		position: relative;
		display: inline-flex;	
		transition: @buttonElm[transition]; transition: var(--transition);
		
		&:active {
			//.shadow--none;
			transform: translateY(0.1rem);
		}		
	
		&__inner {
			// reset button
			background-color: transparent;		
			outline: none;
			color: inherit;
			white-space: nowrap;			
			overflow: hidden;
			border: 0px solid;
			
			position: relative;
			height: @buttonElm[height]; height: var(--height);
			display: flex;
			align-items: center;
			flex-grow: 1;
			padding-left: @buttonElm[paddingLeft]; padding-left: var(--paddingLeft);
			padding-right: @buttonElm[paddingRight]; padding-right: var(--paddingRight);
			
			transition: inherit;
			background-color: @buttonElm[activeBackgroundColor]; background-color: var(--activeBackgroundColor);
			color: @buttonElm[activeForegroundColor]; color: var(--activeForegroundColor);
			border-radius: @buttonElm[borderRadius]; 
			border-radius: var(--borderRadius);
		}
		&:hover &__inner {
			background-color: @buttonElm[hoverBackgroundColor]; background-color: var(--hoverBackgroundColor);			
		}
		&__border {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			pointer-events: none;
			border: @buttonElm[border]; 
			border: var(--border);
			border-radius: @buttonElm[borderRadius]; 
			border-radius: var(--borderRadius);
			transition: inherit;
		}
		&:hover &__border {
			border: @buttonElm[hoverBorder]; 
			border: var(--hoverBorder);
		}
	}
	/*
	.BaseButton--isDisabled {
		opacity: 0.75;
		pointer-events: none;
	}
	.BaseButton--loading {
		//opacity: 0.5;
		overflow: hidden;
		
		.BaseButton__inner {
			transform: translateY(100%);
		}
		
		.BaseButton__inner::before {
			position: absolute;
			top: 50%; left: 50%;
			content: "";
			height: 1em; width: 1em;
			transform: translateX(-50%) translateY(-250%);
			animation: BaseButtonSpin 1s linear infinite;
			
			clip-path: polygon(50% 0%, 50% 50%, 100% 50%, 100% 100%, 0 100%, 0 0);
			border: 2px solid currentColor;
			border-radius: 50%;
			//border-left-color: black; 
		}
		
		@keyframes BaseButtonSpin { 
			0% { 	transform: translateX(-50%) translateY(-250%); } 
			100% { 	transform: translateX(-50%) translateY(-250%) rotate(360deg); } 
		}
	}
	*/
</style>
