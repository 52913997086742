<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="AbSubHeadline contentModule" :class="elmClasses">
		<div class="AbSubHeadline__inner contentModule__inner font layout hSpace hSpace--page maxWidth maxWidth--page" :class="elmInnerClasses">
			<!--
			AbSubHeadline
			<pre name="$options.propsData">{{$options.propsData}}</pre>
			-->
			<h2 class="richText" v-html="text"></h2>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'AbSubHeadline',
		components: {},
		mixins: [],
		props: {
			color: {
      			type: [String, Boolean],
      			default: 'grey',
    		},
			text: {
      			type: [String, Boolean],
      			default: '',
    		},
		},
		data() {
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
			elmInnerClasses(){
				let classes = []

				classes.push('font--serif')
				classes.push('font--color' + this._.upperFirst(this.color))
				classes.push('font--sizeDefault')
				classes.push('layout--□□☰☰☰☰☰☰☰☰□□')

				return classes
			},
		},
		methods: {},
		created() {
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted() {
			//console.log(this)
		},
		destroyed () {
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.AbSubHeadline {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
