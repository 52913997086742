<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="AbHomeFeatures contentModule" :class="elmClasses">
		<div class="AbHomeFeatures__inner contentModule__inner hSpace hSpace--page maxWidth maxWidth--page" :class="elmInnerClasses" v-if="features">
			<!-- next event slot -->
			<template v-if="eventPost">
				<a class="AbHomeFeatures__item AbHomeFeatures__item--eventPost AbHomeFeature" :href="eventPost.link">
					<h3 class="AbHomeFeature__overTitle font font--sans font--bold font--sizeSmall">Nächste Veranstaltung&nbsp;</h3>
					<p  class="AbHomeFeature__eventCat font font--sans font--bold font--sizeSmall color color--lighterBlue" v-html="eventPost.acf.category"></p>
					<h2 class="AbHomeFeature__title font font--serif font--sizeDefault color color--blue">
						{{eventPost.title.rendered}}
						<span class="color color--pink" v-if="eventPost.acf.titleAppendix">
							– {{eventPost.acf.titleAppendix}}
						</span>
					</h2>
					<div class="AbHomeFeature__eventDateTime font font--sans font--bold font--sizeSmall color color--lighterBlue">
						<span v-html="getDateString( eventPost.acf.date )"></span><br />
						{{eventPost.acf.time}}<template v-if="eventPost.acf.time">&nbsp;Uhr</template><br />
					</div>
					<div class="font font--sans font--sizeSmall" v-html="eventPost.acf.teaserText"></div>
				</a>
			</template>
			<template v-else>
				<div class="AbHomeFeatures__item AbHomeFeature">
				</div>
			</template>
			<!-- feature slots -->
			<div class="AbHomeFeatures__item AbHomeFeature" v-for="(feature, i) in features" :key="i">

				<h3 class="AbHomeFeature__overTitle font font--sans font--bold font--sizeSmall">{{feature.overTitle}}&nbsp;</h3>
				<h2 class="AbHomeFeature__title font font--serif font--sizeDefault" v-html="feature.title"></h2>
				<MhImage class="AbHomeFeature__image" :imageObject="feature.image" :mode="'cover'" vif="feature.image"></MhImage>
				<div class="AbHomeFeature__text richText font--sizeSmall" v-html="feature.text"></div>

			</div>
			<!--
			<pre name="features">{{features}}</pre>
			-->
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'

	export default {
		name: 'AbHomeFeatures',
		components: {
			MhImage,
		},
		mixins: [ restHandler ],
		props: {
			features: [Array, Boolean],
		},
		data() {
			return {
				eventPost : null,
				dayNames : [
					'Sonntag',
					'Montag',
					'Dienstag',
					'Mittwoch',
					'Donnerstag',
					'Freitag',
					'Samstag',
				],
				monthNames : [
					'Januar',
					'Februar',
					'März',
					'April',
					'Mai',
					'Juni',
					'Juli',
					'August',
					'September',
					'Oktober',
					'November',
					'Dezember',
				],
			}
		},
		watch: {
			/*
			features: {
				handler: function( to, from ) {
					console.log('features changed')
					this.fetchEvent()
				},
				deep: true,
			}
			*/
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			elmInnerClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {
			getDateString( dateInt ){
				let returnStr = ''

				if( dateInt ){
					const ymd       = dateInt + ''
					const y         = ymd.substring(0, 4)
					const m         = ymd.substring(4, 6)
					const d         = ymd.substring(6, 8)
					const ymdStr    = y + '-' + m + '-' + d

					const dateObj   = new Date( ymdStr )
					const getDay    = dateObj.getDay()
					const dayName   = this.dayNames[getDay]
					const monthDay  = dateObj.getDate()
					const getMonth  = dateObj.getMonth()
					const monthName = this.monthNames[getMonth]

					/*
					console.log('---')
					console.log('y:', y)
					console.log('m:', m)
					console.log('d:', d)
					console.log('ymdStr:', ymdStr)
					console.log('dateObj:', dateObj)
					console.log('getDay :', getDay)
					console.log('dayName:', dayName)
					console.log('getMonth:', getMonth)
					console.log('monthName:', monthName)
					*/

					returnStr = dayName + ' ' + monthDay + '.&nbsp;' + monthName
					//returnStr = returnStr + ' ' + dateInt
				}

				return returnStr
			},
			fetchNextEvent() {
				// fetch next event
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/ab__events',
					params : {
						range   : 'nowAndFuture',
						perPage : 5,
						preventCache : this._.random( 100, 999 ),
					},
					callback : (response) => {
						this.eventPost = response.data.result[0]

						// send loaded to MhView to hide the spinner again
						EventBus.$emit('stateChanged', 'loaded')
					},
				})

			},
		},
		created() {},
		mounted() {
			this.fetchNextEvent()
		},
		destroyed () {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.AbHomeFeatures {}
	.AbHomeFeatures__inner {
		.grid;
		.grid--setAreas;
		.grid--columnGap;

		padding-top: 0.65em;
		padding-bottom: 1em;

		@media @mq[dt] {
			.grid--cols-12;
			grid-template-areas: "a a a a b b b b c c c c";
		}
		@media @mq[md] {
			.grid--cols-8;
			grid-template-areas: ". . a a a a . ."
								 "b b b b c c c c";
		}
		@media @mq[sm] {
			.grid--cols-1;
			grid-template-areas: "a"
								 "b"
								 "c";
		}
	}

	.AbHomeFeature {
		[showBorders3] & { outline: 1px solid green; }

		padding-bottom: 1em;
	}
	.AbHomeFeatures__item--eventPost {
		//background-color: fade( red, 20 );
		color: inherit;
		text-decoration: inherit;
	}
	.AbHomeFeature__overTitle {
		color: @swatches[pink];
		padding-bottom: 0.5em;
	}
	.AbHomeFeature__title {
		color: @swatches[blue];
		//min-height: 2.5em;
		padding-bottom: 0.85em;
	}
	.AbHomeFeature__eventCat {
		margin-bottom: 0.3em;
	}
	.AbHomeFeature__eventDateTime {
		margin-top: -0.85em;
		margin-bottom: 1em;
	}
	.AbHomeFeature__image {
		margin-bottom: 1em;

		@media @mq[dt] {
			padding-bottom: 53%;
		}
		@media @mq[md] {
			padding-bottom: 53%;
		}
		@media @mq[sm] {
			padding-bottom: 53%;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
