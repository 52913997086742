<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="AbMainHeadline contentModule" :class="elmClasses">
		<div class="AbMainHeadline__inner contentModule__inner font layout hSpace hSpace--page maxWidth maxWidth--page" :class="elmInnerClasses">
			<!--
			AbMainHeadline
			<pre name="$options.propsData">{{$options.propsData}}</pre>
			-->
			<h1 class="richText" v-html="text"></h1>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'AbMainHeadline',
		components: {},
		mixins: [],
		props: {
			color: {
				type: [String, Boolean],
				default: 'grey',
			},
			text: {
				type: [String, Boolean],
				default: '',
			},
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
			elmInnerClasses(){
				let classes = []

				classes.push('font--serif')
				classes.push('font--colorBlue')
				classes.push('font--sizeBig')
				classes.push('layout--☰☰☰☰☰☰☰☰☰☰☰☰')

				return classes
			},
		},
		methods: {},
		created() {
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted() {},
		destroyed () {
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.AbMainHeadline {
		@media @mq[dt] {
			padding-top: 1.2em;
			padding-bottom: 1em;
		}
		@media @mq[md] {
			padding-top: 1.1em;
			padding-bottom: 1em;
		}
		@media @mq[sm] {
			padding-top: 1em;
			padding-bottom: 1em;
		}

	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
