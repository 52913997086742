<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="AbSearchResult" :class="elmClasses">
		<div class="AbSearchResult__inner">
			<div class="AbSearchResult__post" :class="'AbSearchResult--postType-' + post.type">
				<div class="AbSearchResult__dateTime font font--sans font--bold font--sizeSmall" v-if="post.type == 'veranstaltung_post'">
					<span class="color color--lighterBlue" v-html="dateString"></span>
					<span class="color color--lighterBlue" v-if="post.acf.time"><br />{{post.acf.time}}&nbsp;Uhr <br /></span>
					<!--
					<span v-html="getDateString( post.acf.date )"></span><br />
					{{post.acf.dateYear}}<br />
					{{post.acf.dateMonth}}
					-->
				</div>
				<div class="AbSearchResult__overTitle color color--lighterBlue font font--sans font--bold font--sizeSmall" v-html="getOverTitle( post )"></div>
				<div class="AbSearchResult__body">
					<h4 class="AbSearchResult__bodyTitle color color--blue font font--serif">
						<template v-if="post.type =='veranstaltung_post'">
							<router-link :to="{ name: 'EventView', params: { slug : post.slug } }">
								{{post.title.rendered}}
							</router-link>
						</template>
						<template v-if="post.type =='nachlese_post'">
							<router-link :to="{ name: 'NachleseViewDetail', params: { slug : post.slug } }">
								{{post.title.rendered}}
							</router-link>
						</template>
						<template v-if="post.type =='page'">
							<router-link :to="{ name: 'PageView', params: { slug : post.slug } }">
								{{post.title.rendered}}
							</router-link>
						</template>
					</h4>

					<template v-if="post.type == 'veranstaltung_post'">
						<div class="font font--sans font--sizeSmall" v-html="post.acf.teaserText"></div>
					</template>
				</div>
			</div>

			<!--
			<pre name="post">{{post}}</pre>
			<pre name="post.title.rendered">{{post.title.rendered}}</pre>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import AbContentModules from '@/components/AbContentModules.vue'

	export default {
		name: 'AbSearchResult',
		components: {
			AbContentModules,
		},
		mixins: [],
		props: {
			post: [Object, Boolean],
		},
		data() {
			return {
				dayNames : [
					'Sonntag',
					'Montag',
					'Dienstag',
					'Mittwoch',
					'Donnerstag',
					'Freitag',
					'Samstag',
				],
				monthNames : [
					'Januar',
					'Februar',
					'März',
					'April',
					'Mai',
					'Juni',
					'Juli',
					'August',
					'September',
					'Oktober',
					'November',
					'Dezember',
				],
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			dateString(){
				let dateStr   = ''
				let returnStr = ''

				if( this.post.type == 'nachlese_post' ){
					const ts = Date.parse( this.post.date )
					const dateObj = new Date();
					const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
					const date = ('0' + dateObj.getDate()).slice(-2);
					const year = dateObj.getFullYear();
					const shortDate = year + month + date;

					//console.log('ts:', ts)
					//console.log('shortDate:', shortDate)

					dateStr = shortDate
				}else{
					dateStr = this._.get(this.post, 'acf.date') + ''
				}

				//console.log('dateStr:', dateStr)

				// erwartet "20201230"
				if( dateStr ){
					const ymd       = dateStr
					const y         = ymd.substring(0, 4)
					const m         = ymd.substring(4, 6)
					const d         = ymd.substring(6, 8)
					const ymdStr    = y + '-' + m + '-' + d

					const dateObj   = new Date( ymdStr )
					const getDay    = dateObj.getDay()
					const dayName   = this.dayNames[getDay]
					const monthDay  = dateObj.getDate()
					const getMonth  = dateObj.getMonth()
					const monthName = this.monthNames[getMonth]

					/*
					console.log('---')
					console.log('y:', y)
					console.log('m:', m)
					console.log('d:', d)
					console.log('ymdStr:', ymdStr)
					console.log('dateObj:', dateObj)
					console.log('getDay :', getDay)
					console.log('dayName:', dayName)
					console.log('getMonth:', getMonth)
					console.log('monthName:', monthName)
					*/

					returnStr = dayName + ' ' + monthDay + '.&nbsp;' + monthName + ' ' + y
					//returnStr = returnStr + ' ' + dateInt
				}

				if( this.post.type == 'nachlese_post' ){
					const ts = Date.parse( this.post.date )
					const dateObj = new Date()
					const year = dateObj.getFullYear()

					returnStr = returnStr + '<br />' + year
				}
				return returnStr
			},
		},
		methods: {
			getOverTitle( post ){
				const postCat = this._.lowerCase( this._.get(post, 'acf.category') )
				let overTitle = ''

				if( postCat == 'sonstige' ){
					overTitle = this._.get(post, 'acf.customCategory')
				}else{
					overTitle = this._.get(post, 'acf.category')
				}

				return overTitle
			},
		},
		created() {},
		mounted() {},
		destroyed () {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.AbSearchResult {
		//outline: 1px solid red;
		//background-color: fade( red, 20 );
	}
	.AbSearchResult__post {

	}
	.AbSearchResult__post {
		margin-bottom: 1em;

		.AbSearchResult__bodyTitle {
			a {
				text-decoration: none;
				color: inherit;
			}
		}
	}
	.AbSearchResult--postType-veranstaltung_post {
		//background-color: fade(yellow, 50);

		.grid;
		.grid--columnGap;
		.grid--setAreas;

		[showBorders3] & > * { outline: 1px dotted red; }

		@media @mq[dt] {
			.grid--cols-8;
			grid-template-areas: "a a a b b b . ."
								 "c c c c c c . .";
		}
		@media @mq[md] {
			.grid--cols-7;
			grid-template-areas: "a a b b b . ."
								 "c c c c c . .";
		}
		@media @mq[sm] {
			.grid--cols-4;
			grid-template-areas: "a a a a"
								 "b b b b"
								 "c c c c";
		}

		.AbSearchResult__dateTime {
			@media @mq[dt] {}
			@media @mq[md] {}
			@media @mq[sm] {}
		}
		.AbSearchResult__overTitle {
			@media @mq[dt] { text-align: right; }
			@media @mq[md] { text-align: right; }
			@media @mq[sm] { text-align: left; }
		}
		.AbSearchResult__bodyTitle {
			padding-top: 0.25em;
			padding-bottom: 1em;
		}
	}


	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
