<template>
	<div class="HomeView view">

		<div class="minHeight minHeight--100">
			<AbMainHeader></AbMainHeader>

			<AbHomeGallery :images="homeGalleryImages"></AbHomeGallery>
			<AbHomeIntroText :text="homeIntroText"></AbHomeIntroText>
			<AbHomeFeatures :features="homeFeatures"></AbHomeFeatures>
		</div>

		<AbMainFooter></AbMainFooter>

		<!--
		<pre name="page.acf">{{page.acf}}</pre>
		<pre data-name="pages">{{pages}}</pre>
		-->

	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'
	import ViewMixin from '@/views/View.mixin.js'

	import AbMainHeader from '@/components/AbMainHeader.vue'
	import AbHomeGallery from '@/components/AbHomeGallery.vue'
	import AbHomeIntroText from '@/components/AbHomeIntroText.vue'
	import AbHomeFeatures from '@/components/AbHomeFeatures.vue'
	import AbMainFooter from '@/components/AbMainFooter.vue'

	export default {
		name: 'HomeView',
		components: {
			AbMainHeader,
			AbHomeGallery,
			AbHomeIntroText,
			AbHomeFeatures,
			AbMainFooter,
		},
		mixins: [ restHandler, ViewMixin ],
		directives: {},
		props: {
		},
		data() {
			return {
				page: {},
			}
		},
		computed: {
			homeGalleryImages(){
				return this._.get( this.page, 'acf.gallery' )
			},
			homeIntroText(){
				return this._.get( this.page, 'acf.introText' )
			},
			homeFeatures(){
				const features = this._.compact([
					//this._.get( this.page, 'acf.featureSlot1' ),
					this._.get( this.page, 'acf.featureSlot2' ),
					this._.get( this.page, 'acf.featureSlot3' ),
				])

				return features ? features : null
			},
		},
		methods: {},
		mounted() {
			// fetch page
			this.restHandler__fetch({
				action : 'GET',
				route : '/wp-json/mh/v1/posts',
				params : {
					slug : 'startseite',
				},
				callback : (response) => {
					this.page = response.data.result[0]

					this.viewIsReady = true
				},
			})
		},
	}
</script>

<style lang="less">
	//@import "../less/includes/grid.include.less";
	@import "../less/vars.less";

	.HomeView {
		//padding: 1em;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
