<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="AbMainHeader contentModule" :class="elmClasses">
		<div class="AbMainHeader__inner contentModule__inner hSpace hSpace--page maxWidth maxWidth--page" :class="elmInnerClasses">

			<div class="AbMainHeader__wrapper">

				<h6 class="AbMainHeader__brand">
					<router-link class="AbMainHeader__brandLink" :to="'/'">
						<span>Abtei St. Stephan</span>
					</router-link>
				</h6>

				<div class="flex flex--grow"></div>

				<nav class="AbMainHeader__linkGroups font font--sans font--bold font--sizeSmall" v-if="headerLinkGroups">
					<div class="AbMainHeader__linkGroup"
						 v-for="(linkGroup, i) in headerLinkGroups" :key="i"
						 :tabindex="-1"
						 :class="{ 'AbMainHeader__linkGroup--isActive' : (activeLinkGroup === i) }"
						 >
						<a class="AbMainHeader__linkGroupTitle" role="button">{{linkGroup.title}}</a>
						<ul class="AbMainHeader__linkGroupList">
							<li class="AbMainHeader__linkGroupListItem font font--bold" v-for="(item, j) in linkGroup.links" :key="j">
								<a class="AbMainHeader__sitemapLink font font--bold" :href="item.link">{{item.text}}</a>
							</li>
						</ul>
						<br />
					</div>
				</nav>

				<div class="AbMainHeader__searchBtn" role="button" :tabindex="-1"
					 @focus="isFocusedSearchBtn = true"
					 @blur="isFocusedSearchBtn = false"
					 @mousedown="clickSearchBtn( $event, 'mousedown')"
					 @click="clickSearchBtn( $event, 'click')">
					<div class="AbMainHeader__searchBtnIcon"></div>
				</div>
				<form class="AbMainHeader__searchForm hSpace hSpace--page"
					  :class="{'AbMainHeader__searchForm--isFocused' : this.$route.name.includes('SearchResultsView') }"
					  @submit.prevent="submitSearchForm( $event )">
					<div class="AbMainHeader__searchFormInner">
						<input type="text" class="AbMainHeader__searchFormInput font font--sizeSmall" v-model="searchString" />
						<div class="AbMainHeader__searchFormIcon"></div>
					</div>
				</form>

				<div class="AbMainHeader__menuBtn" role="button" @click="mobileMenuIsActive = true">
					<div class="AbMainHeader__menuBtnIcon"></div>
				</div>
			</div>


			<!--
			<pre name="headerLinkGroups">{{headerLinkGroups}}</pre>
			-->
		</div>
		<div class="AbMobileMenu background background--blue" :class="{'AbMobileMenu--isActive' : mobileMenuIsActive}">
			<div class="AbMobileMenu__inner">

				<div class="AbMobileMenu__header hSpace hSpace--page">
					<div class="AbMobileMenu__brand">
						<router-link class="AbMobileMenu__brandLink" :to="'/'">
							<span>Abtei St. Stephan</span>
						</router-link>
					</div>
					<a   class="AbMobileMenu__closeBtn"
						 role="button"
						 @click="mobileMenuIsActive = false">
						<div class="AbMobileMenu__closeBtnIcon"></div>
					</a>
				</div>
				<div class="AbMobileMenu__body flex flex--grow" :class="{'hSpace hSpace--page' : $root.isTablet}">
					<nav class="AbMobileMenu__linkGroups font font--sans font--sizeSmall color color--white" v-if="headerLinkGroups">
						<div class="AbMobileMenu__linkGroup"
							 v-for="(linkGroup, i) in headerLinkGroups" :key="i">
							<div class="AbMobileMenu__linkGroupTitle" role="button" @click="clickLinkGroupTitle(i)">
								{{linkGroup.title}}
								<span class="AbMobileMenu__linkGroupToggleIndicator" v-if="$root.isPhone" :class="{'AbMobileMenu__linkGroupToggleIndicator--isActive' : getLinkGroupActiveState( i ) }"></span>
								<!--{{getLinkGroupActiveState( i )}}-->
							</div>
							<div class="AbMobileMenu__linkGroupListWrapper" v-show-slide="getLinkGroupActiveState( i )">
								<ul class="AbMobileMenu__linkGroupList">
									<li class="AbMobileMenu__linkGroupListItem" v-for="(item, j) in linkGroup.links" :key="j">
										<a class="AbMobileMenu__linkGroupLink font font--bold" :href="item.link">{{item.text}}</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>

					<div class="flex flex--grow" v-if="$root.isTablet"></div>

					<div class="AbMobileMenu__footerLinks font font--sans font--sizeSmall color color--white" v-if="footerLinks">
						<a 	class="AbMobileMenu__footerLink"
							v-for="(link, i) in footerLinks" :key="i"
							:href="link.link"
						>{{link.text}}</a>
					</div>

					<div class="flex flex--grow" v-if="$root.isPhone"></div>

					<div class="AbMobileMenu__smLogo hSpace hSpace--page" v-if="$root.isPhone">
						<div class="AbMobileMenu__smLogoImage"></div>
					</div>
				</div>
				<div class="AbMobileMenu__footer hSpace hSpace--page" v-if="$root.isTablet">
					<div class="AbMobileMenu__mdLogo">
						<div class="AbMobileMenu__mdLogoImage"></div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'AbMainHeader',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
		},
		data() {
			return {
				activeLinkGroup       : 1,
				mobileMenuIsActive    : false,
				activeLinkGroupTitles : [],

				searchString          : this._.get(this.$route.params, 'searchString'),
				isFocusedSearchBtn    : false,
				doBlurSearchBtn       : false,
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			elmInnerClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			headerLinkGroups(){
				return this._.get( this.app, 'acfOptions.headerLinkGroups')
			},
			footerLinks(){
				return this._.get( this.app, 'acfOptions.footerLinks')
			},
		},
		methods: {
			getLinkGroupActiveState( index ){
				if( !this.$root.isPhone ){
					return true
				}else{
					return this._.includes( this.activeLinkGroupTitles, index ) ? true : false
				}
			},
			clickLinkGroupTitle( index ){
				// is active > remove from active
				if( this._.includes( this.activeLinkGroupTitles, index ) ){
					this.activeLinkGroupTitles = this._.without( this.activeLinkGroupTitles, index )
				}
				// isnt active yet > add to active
				else{
					this.activeLinkGroupTitles.push( index )
				}

				//console.log('activeLinkGroupTitles:', this.activeLinkGroupTitles)
			},
			clickSearchBtn( e, type ){
				const isFocusedSearchBtn = this.isFocusedSearchBtn
				const searchBtnElm       = e.target

				/*
				console.log('type:', type)
				console.log('isFocusedSearchBtn:', isFocusedSearchBtn)
				console.log('searchBtnElm:', searchBtnElm)
				console.log('')
				*/

				if( this.doBlurSearchBtn ){
					 searchBtnElm.blur()
					 this.doBlurSearchBtn = false
				}
				if( type == 'mousedown' && isFocusedSearchBtn ) this.doBlurSearchBtn = true
			},
			submitSearchForm( e ){
				const searchString = this.searchString

				console.group('submitSearchForm()')
				console.log('searchString:', searchString)
				console.groupEnd()
				/*
				*/

				if( this.$route.name.includes('SearchResultsView') ){
					this.$router.replace({ path: '/search/' + searchString })
				}
				else{
					this.$router.push({ path: '/search/' + searchString })
				}
			},
		},
		created() {},
		mounted() {},
		destroyed () {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.AbMainHeader {
		// prevent overflow if text is too long
		position: relative;
		//max-width: 100vw;
		//overflow: hidden; // tja, das darf eigentlich nicht sein, aber ansonsten gibt es einen overflow!

		@media @mq[sm] {
			background-color: white;
			position: sticky;
			top: 0;
			z-index: 5;
		}
	}
	.AbMainHeader__wrapper { // height
		//& > * { outline: 1px solid green; }

		display: flex;
		justify-content: space-between;

		border-bottom: 1px solid @swatches[blue];

		@media @mq[dt] {
			height: 72px;
		}
		@media @mq[md] {
			height: 72px;
		}
		@media @mq[sm] {
			height: 50px;
		}
	}
	.AbMainHeader__brand { // wortmarke
		position: relative;
		color: transparent;
		overflow: hidden;
		display: flex;
		flex-shrink: 0;

		@media @mq[dt] { padding-bottom: 0.25em; width: 145px; }
		@media @mq[md] { padding-bottom: 0.5em; width: 145px; }
		@media @mq[sm] { padding-bottom: 0.1em; width: 120px; }

		&Link {
			display: block;
			flex-grow: 1;

			background-image: url(../assets/images/brand--blue.svg);
			background-repeat: no-repeat;
			background-size: contain;
			background-position: left center;
			//width: 12%;
			//padding-bottom: 47%;
			//position: absolute;
			//top: 0; left: 0; right: 0; bottom: 0;
		}
		span {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			opacity: 0;
		}
	}

	.AbMainHeader__linkGroups {
		//& > * { outline: 1px dotted green; }

		display: flex;
		flex-shrink: 0;

		@media @mq[dt] {
			display: flex;
		}
		@media @mq[md] {
			display: none;
		}
	}
	.AbMainHeader__linkGroup {
		position: relative;
		display: flex;
		flex-shrink: 0;
		margin-left: 1.1em; // space between

		&Title {
			transition: all 0.1s ease;
			color: @swatches[gold];
			display: inline-flex;
			align-items: center;
			white-space: nowrap;
			cursor: pointer;
			padding-top: 0.5em;

			&:hover {
				color: @swatches[blue];
			}
		}
		&List {
			position: absolute;
			top: 100%; left: 50%;
			margin: 0; list-style: none;
			max-width: 350px;
			transform: translateX(-50%);
			padding: 1em 1em;
			z-index: 10;

			opacity: 0;
			pointer-events: none;
			background-color: @swatches[blue];
			transition: all 0.2s ease;

			&::before { // dreieck an der oberen kante
				position: absolute;
				top: 0; left: 50%;
				height: 25px; width: 25px;
				transform: translateX(-50%) translateY(-10px) rotate(45deg);
				background-color: @swatches[blue];
				content: "";
			}
		}
		&ListItem {
			text-align: center;

			a {
				transition: all 0.1s ease;
				display: block;
				overflow: hidden;
				width: 100%;
				text-overflow: ellipsis;
				color: white;
				text-decoration: none;
				line-height: 2.5em;
				white-space: nowrap;
			}

			&:hover a {
				color: @swatches[gold];
			}
		}
	}
	.AbMainHeader__linkGroup:focus,
	.AbMainHeader__linkGroup:focus-within {
		outline: none;
		border: none;
		z-index: 20;

		.AbMainHeader__linkGroupTitle {
			color: @swatches[blue];
		}
		.AbMainHeader__linkGroupList {
			pointer-events: auto;
			opacity: 1;
		}
	}

	.AbMainHeader__menuBtn {
		[showBorders4] & { outline: 1px solid red; }

		position: relative;
		display: flex;
		width: 40px;
		padding-left: 15px;
		cursor: pointer;

		@media @mq[dt] {
			display: none;
		}
		@media @mq[md] {
			padding-top: 5px;
			display: flex;
		}
		@media @mq[sm] {
			padding-top: 0px;
			display: flex;
		}

		&Icon {
			display: block;
			flex-grow: 1;
			//transform: translateX(-1em);
			//margin-right: -1em;

			background-image: url(../assets/images/icon--hamburger.svg);
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
		}
	}

	.AbMainHeader__searchBtn {
		[showBorders4] & { outline: 1px solid red; }

		position: relative;
		display: flex;
		padding-left: 15px;
		padding-right: 14px;

		cursor: pointer;

		@media @mq[dt] {
			width: 40px;
			padding-top: 8px;
			padding-left: 15px;
			padding-right: 0px;
		}
		@media @mq[md] {
			width: 54px;
			padding-top: 8px;
			padding-left: 15px;
			padding-right: 14px;
		}
		@media @mq[sm] {
			padding-top: 0px;

			width: 35px;
			padding-left: 15px;
			padding-right: 0px;
		}

		&Icon {
			display: block;
			flex-grow: 1;
			pointer-events: none;

			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;

			@media @mq[dt] {
				background-image: url(../assets/images/icon--lense-lightBlue.svg);
			}
			@media @mq[md] {
				background-image: url(../assets/images/icon--lense-blue.svg);
			}
		}

		&:focus {
			outline: none;
			border: none;
		}
	}
	.AbMainHeader__searchForm {
		position: absolute;
		top: 100%; left: 0%; right: 0;
		margin: 0; list-style: none;
		z-index: 10;

		//opacity: 0;
		transition: all 0.2s ease;
		pointer-events: none;
		opacity: 0;

		&Inner { // height via padding
			position: relative;
			background-color: @swatches[lighterBlue];

			@media @mq[dt] {
				padding: 0.65em;
				padding-left: 3em;
			}
			@media @mq[md] {
				padding: 0.8em;
				padding-left: 3em;
			}
			@media @mq[sm] {
				padding: 0.5em;
				padding-left: 1.85em;
			}
		}
		&Icon {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			@media @mq[dt] { left: 1em;   width: 0.85em;  height: 0.85em; }
			@media @mq[md] { left: 1em;   width: 0.85em;  height: 0.85em; }
			@media @mq[sm] { left: 0.5em; width: 0.85em; height: 0.85em; }

			opacity: 0.5;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			background-image: url(../assets/images/icon--lense-white.svg?1);
		}
		&Input {
			width: 100%;
			background-color: transparent;
			border: none;
			outline: none;
			color: white;
			border-bottom: 1px solid fade( white, 50 );
		}

		&::before { // dreieck an der oberen kante
			position: absolute;
			top: 0;
			height: 18px; width: 18px;
			transform: translateX(-35px) translateY(-50%) rotate(45deg);
			background-color: @swatches[lighterBlue];
			content: "";

			@media @mq[dt] {
				right: 0em;
				height: 18px; width: 18px;
			}
			@media @mq[md] {
				right: 2em;
				height: 18px; width: 18px;
			}
			@media @mq[sm] {
				right: 4.5%;
				height: 14px; width: 14px;
			}
		}
	}
	.AbMainHeader__searchBtn:focus + .AbMainHeader__searchForm,
	.AbMainHeader__searchForm--isFocused,
	.AbMainHeader__searchForm:focus,
	.AbMainHeader__searchForm:focus-within {
		opacity: 1;
		pointer-events: auto;
	}


	// Mobile Menu

	.AbMobileMenu {
		//opacity: 0.5;

		position: fixed;
		top: 0; left: 0; right: 0;
		height: 100vh;
		z-index: 10;
		transition: all 0.35s ease;
		transform: translateY(-120%);
		overflow: hidden;

		&--isActive {
			transform: translateY(0%);
		}

		@media @mq[dt] { display: none; }
		@media @mq[md] { display: flex; }
		@media @mq[sm] { display: flex; }
	}
	.AbMobileMenu__inner {
		[showBorders3] & > * { outline: 1px solid fade( red, 75 ); }

		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
	.AbMobileMenu__header { // height
		display: flex;
		justify-content: space-between;

		@media @mq[dt] {
			height: 72px;
		}
		@media @mq[md] {
			height: 72px;
		}
		@media @mq[sm] {
			height: 50px;
		}
	}
	.AbMobileMenu__body {
		display: flex;
		flex-direction: column;

		@media @mq[md] {
			padding-top: 3em;
			overflow-x: hidden;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
		}
		@media @mq[sm] {
			padding-top: 2em;
		}
	}
	.AbMobileMenu__footer {
		//background-color: fade( yellow, 70 );

		position: relative;
		height: 200px;
		display: flex;
	}

	.AbMobileMenu__brand { // wortmarke
		[showBorders3] & { outline: 1px solid fade( yellow, 35 ); }

		position: relative;
		color: transparent;
		overflow: hidden;
		display: flex;
		flex-shrink: 0;

		@media @mq[dt] { padding-bottom: 0.25em; width: 145px; }
		@media @mq[md] { padding-bottom: 0.5em; width: 145px; }
		@media @mq[sm] { padding-bottom: 0.1em; width: 120px; }

		&Link {
			display: block;
			flex-grow: 1;

			background-image: url(../assets/images/brand--white.svg);
			background-repeat: no-repeat;
			background-size: contain;
			background-position: left center;
			//width: 12%;
			//padding-bottom: 47%;
			//position: absolute;
			//top: 0; left: 0; right: 0; bottom: 0;
		}
		span {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			opacity: 0;
		}
	}
	.AbMobileMenu__closeBtn {
		[showBorders3] & { outline: 1px solid fade( yellow, 35 ); }

		position: relative;

		display: flex;
		user-select: none;
		color: transparent;
		overflow: hidden;
		width: 50px;
		//margin-right: -1em;
		overflow: hidden;

		@media @mq[md] { padding: 22px 0; }
		@media @mq[sm] { padding: 12px 0; }

		&Icon {
			display: block;
			//margin-right: 1em;
			flex-grow: 1;

			background-image: url(../assets/images/icon--cross.svg);
			background-repeat: no-repeat;
			background-size: contain;
			background-position: right center;
		}
	}
	.AbMobileMenu__linkGroups {
		.grid;
		.grid--columnGap;
		.grid--cols-3;

		@media @mq[md] {
			.grid--cols-3;
			font-size: 16px;
		}
		@media @mq[sm] {
			.grid--cols-1;
			font-size: 18px;
		}

		width: 100%;
		grid-auto-rows: min-content;
	}
	.AbMobileMenu__linkGroup {
		[showBorders3] & { outline: 1px solid fade( cyan, 35 ); }

		@media @mq[md] {
			padding-bottom: 3em;
		}
		@media @mq[sm] {
			padding-bottom: 0em;
		}

		&Title {
			@media @mq[md] {
				padding-bottom: 0.25em;
			}
			@media @mq[sm] {
				text-align: center;
				padding: 0.5em 0;
				cursor: pointer;
			}
		}
		&ToggleIndicator {
			//background-color: red;

			display: inline-flex;
			width: 0.5em; height: 0.65em;
			margin-left: 0.35em;

			background-image: url(../assets/images/icon--triangleDown.svg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;

			&--isActive {
				transform: rotate(180deg);
			}
		}
		&List {
			.reset--list;

			position: relative;
			//padding: 0.4em 0;

			@media @mq[sm] {
				margin: 0.5em 0;
				padding: 0.5em 0;
				text-align: center;
				background-color: #1478C2;
			}
		}
		&ListItem {
			padding-left: 0;
		}
		&Link {
			display: block;
			color: inherit;
			text-decoration: none;

			@media @mq[md] {
				padding: 0.25em 0;
			}
			@media @mq[sm] {
				padding: 0.5em 0;
			}
		}
	}
	.AbMobileMenu__expander {
		@media @mq[sm] {
			display: none;
		}
	}
	.AbMobileMenu__footerLinks {
		[showBorders3] & { outline: 1px solid fade( pink, 85 ); }

		@media @mq[dt] {
			padding: 3em 0;
		}
		@media @mq[md] {
			padding: 3em 0;
		}
		@media @mq[sm] {
			padding: 2.75em 0;
			text-align: center;
		}

		a {
			//outline: inherit;
			//outline-style: dotted;

			color: inherit;
			text-decoration: none;

			@media @mq[md] {
				padding: 0.75em;
				display: inline-block;
			}
			@media @mq[sm] {
				padding: 0.5em;
				display: block;
			}
		}
		a:first-child {
			padding-left: 0;
		}
	}
	.AbMobileMenu__mdLogo {
		position: relative;
		flex-grow: 1;
		overflow: hidden; // logo wird nach unten abgeschnitten

		&Image {
			position: absolute;
			top: 0; left: 0; right: 0;
			bottom: 0px;

			background-image: url(../assets/images/logo--white.svg);
			background-repeat: no-repeat;
			background-position: left bottom;
			background-size: contain;
		}
	}
	.AbMobileMenu__smLogo {
		//background-color: fade( red, 20 );

		position: relative;
		overflow: hidden; // logo wird nach unten abgeschnitten
		display: flex;
		height: 170px;
		flex-shrink: 0;

		&Image {
			display: block;
			flex-grow: 1;
			height: 100%; width: 100%;

			background-image: url(../assets/images/logo--short-white.svg);
			background-repeat: no-repeat;
			background-position: left bottom;
			background-size: contain;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
