<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="AbHomeIntroText contentModule" :class="elmClasses">
		<div class="AbHomeIntroText__inner contentModule__inner hSpace hSpace--page maxWidth maxWidth--page" :class="elmInnerClasses">
			<div class="AbHomeIntroText__text font font--serif richText" v-html="text"></div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'AbHomeIntroText',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			text: [String, Boolean],
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			elmInnerClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
		destroyed () {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.AbHomeIntroText {}
	.AbHomeIntroText__text {
		color: @swatches[blue];

		@media @mq[dt] {
			padding-top: 0.5em;
			padding-bottom: 0.5em;

			max-width: 810px;
			font-size: 40px;
			line-height: 50px;
		}
		@media @mq[md] {
			padding-top: 1em;
			padding-bottom: 1em;

			font-size: 30px;
			line-height: 37px;
		}
		@media @mq[sm] {
			font-size: 25px;
			line-height: 31px;
		}

		em, i {
			font-style: normal;
			color: @swatches[gold];
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
