<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="AbRichText contentModule" :class="elmClasses">
		<div class="AbRichText__inner contentModule__inner font layout maxWidth maxWidth--page" :class="elmInnerClasses">
			<!--
			AbRichText
			<pre name="$options.propsData">{{$options.propsData}}</pre>
			-->
			<div class="richText" v-html="text"></div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'AbRichText',
		components: {},
		mixins: [],
		props: {
			postType: {
				type: [String, Boolean],
				default: '',
			},
			color: {
				type: [String, Boolean],
				default: 'grey',
			},
			text: {
				type: [String, Boolean],
				default: '',
			},
			layout: {
				type: [String, Boolean],
				default: '☰☰☰☰☰☰☰☰☰☰☰☰',
			},
			fontSize: {
				type: [String, Boolean],
				default: 'default',
			},
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
			elmInnerClasses(){
				let classes = []

				if( this.fontSize == 'small' || this.fontSize == 'mini' ){
					classes.push('font--sans')
				}else {
					classes.push('font--serif')
				}
				if( this.postType == 'page' ){
					classes.push('hSpace hSpace--page')
					classes.push('font--size' + this._.upperFirst(this.fontSize))
				}else{
					classes.push('font--sizeSmall')
				}

				classes.push('font--color' + this._.upperFirst(this.color))
				classes.push('layout--' + this.layout)

				if( this.fontSize == 'mini' ) classes.push('font--sizeMini')

				return classes
			},
		},
		methods: {},
		created() {
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted() {},
		destroyed () {
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.AbRichText {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
