import Vue from 'vue'
import App from './App.vue'
import router from './config/router'
import store from './config/store'
import axios from 'axios'
import VueI18n from 'vue-i18n'
import VueAxios from 'vue-axios'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VShowSlide from 'v-show-slide'
import VueMq from 'vue-mq'
import VueDragDrop from 'vue-drag-drop';
import {EventBus} from '@/helper/EventBus.js'

window.$ = window.jQuery = require('jquery');

Vue.config.productionTip = false

Vue.use(VueDragDrop)
Vue.use(VueI18n)
Vue.use(VueAxios, axios)
Vue.use(VueLodash, {lodash: lodash})
Vue.use(VShowSlide)
Vue.use(VueMq, {
	breakpoints: {
		xs: 350,
		sm: 750,
		md: 1023,
		dt: 1500,
		lg: 1799,
		xl: Infinity,
	}
})
/*
@screenWidths : {
	xs : 350px;
	sm : 750px;
	md : 1023px;
	lg : 1500px;
	xl : 1800px;
}
*/

// Setup and mount root
new Vue({
	router,
	store,
	data() {
		return {}
	},
	watch: {},
	computed: {
		isTablet() {
			if (this.$mq === 'md') {
				return true
			} else {
				return false
			}
		},
		isPhone() {
			if (this.$mq === 'sm' || this.$mq === 'xs') {
				return true
			} else {
				return false
			}
		},
	},
	methods: {},
	created() {},
	mounted() {},
	render: h => h(App)
}).$mount('#app')
