<template>
	<div class="SearchResults view">

		<div class="minHeight minHeight--100">
			<AbMainHeader></AbMainHeader>
			<AbMainHeadline class="SearchResults__AbMainHeadline" :text="'Suchergebnisse'" />

			<!-- Veranstaltungen -->
			<div class="SearchResults__group hSpace hSpace--page maxWidth maxWidth--page">
				<h2  class="SearchResults__groupTitle font font--sizeSmall font--bold color--blue">Veranstaltungen</h2>
				<div class="SearchResults__groupFetching font font--sizeSmall" v-if="isFetching.events">
					Suche ...
				</div>
				<div class="SearchResults__groupEmpty font font--sizeSmall" v-if="isEmpty.events">
					Keine Suchergebnisse in Veranstaltungen.
				</div>
				<AbSearchResult class="SearchResults__groupResult" :post="post" v-for="(post, i) in searchResults.events" :key="i"></AbSearchResult>
			</div>

			<!-- Seiteninhalte -->
			<div class="SearchResults__group hSpace hSpace--page maxWidth maxWidth--page">
				<h2  class="SearchResults__groupTitle font font--sizeSmall font--bold color--blue">Seiteninhalte</h2>
				<div class="SearchResults__groupFetching font font--sizeSmall" v-if="isFetching.pages">
					Suche ...
				</div>
				<div class="SearchResults__groupEmpty font font--sizeSmall" v-if="isEmpty.pages">
					Keine Suchergebnisse in Seiteninhalte.
				</div>
				<AbSearchResult class="SearchResults__groupResult" :post="post" v-for="(post, i) in searchResults.pages" :key="i"></AbSearchResult>
			</div>

			<!--
			<pre name="searchString">{{searchString}}</pre>
			<pre name="searchResults">{{searchResults}}</pre>
			-->
		</div>

		<AbMainFooter></AbMainFooter>

		<!--
		<pre data-name="contentModules">{{contentModules}}</pre>
		<pre data-name="page">{{page}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'
	import ViewMixin from '@/views/View.mixin.js'

	import AbMainHeader from '@/components/AbMainHeader.vue'
	import AbMainFooter from '@/components/AbMainFooter.vue'
	import AbMainHeadline from '@/components/AbMainHeadline.vue'
	import AbSearchResult from '@/components/AbSearchResult.vue'

	export default {
		name: 'SearchResults',
		components: {
			AbMainHeader,
			AbMainFooter,
			AbMainHeadline,
			AbSearchResult,
		},
		mixins: [ restHandler, ViewMixin ],
		directives: {},
		props: {},
		data() {
			return {
				searchResults : {
					events    : [],
					pages     : [],
				},
				isFetching : {
					events : true,
					pages  : true,
				},
				isEmpty    : {
					events : false,
					pages  : false,
				},
			}
		},
		/*
		watch: {
			'$route.params.searchString': {
				handler: function( to, from ) {
					console.log('watcher ', from, to);
				},
				intermediate: true,
			}
		},
		*/
		computed: {
			searchString(){
				return this.$route.params.searchString
			},
		},
		methods: {
			fetchSearchResults(){
				const searchString = this.searchString

				/*
				console.group('fetchSearchResults()')
				console.log('searchString:', searchString)
				console.groupEnd()
				*/

				this.isFetching.events = true
				this.isFetching.pages = true

				// fetch pages
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						postType : 'page',
						perPage : 99,
						search : searchString,
					},
					callback : (response) => {
						this.searchResults.pages = response.data.result
						this.isFetching.pages = false
						this.isEmpty.pages = response.data.result.length < 1

						this.viewIsReady = true
					},
				})

				// fetch events
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/ab__events',
					params : {
						range   : 'nowAndFuture',
						perPage : 99,
						search : searchString,
					},
					callback : (response) => {
						let results = response.data.result

						// convert acf.date string to int
						this._.forEach( results, (item, i)=>{
							const dateInt = parseInt( item.acf.date )
							const dateStr = '' + item.acf.date

							results[i].acf.date      = dateInt
							results[i].acf.dateYear  = parseInt( dateStr.substring(0, 4) )
							results[i].acf.dateMonth = parseInt( dateStr.substring(4, 6) )
						})

						// order by date
						results = this._.orderBy(results, ['acf.date', 'acf.time'], ['asc', 'asc'])

						this.searchResults.events = results
						this.isFetching.events = false
						this.isEmpty.events = response.data.result.length < 1
					},
				})
			},
		},
		mounted() {
			console.log('SearchResults mounted')

			this.fetchSearchResults()
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.SearchResults {
		&__AbMainHeadline {
			//background-color: fade( red, 20 );

			@media @mq[dt] {
				padding-top: 4.5em;
			}
			@media @mq[md] {
				padding-top: 4.5em;
			}
			@media @mq[sm] {
				padding-top: 3.5em;
				padding-bottom: 0;
			}
		}

		&__group {
			.grid;
			.grid--columnGap;

			position: relative;
			margin: 1em auto;

			@media @mq[dt] {
				.grid--cols-12;
			}
			@media @mq[md] {
				.grid--cols-8;
			}
			@media @mq[sm] {
				.grid--cols-4;
			}
		}
		&__groupTitle {
			//background-color: fade( green, 10 );

			margin: 1em 0;

			@media @mq[dt] {
				grid-column: 2 / span 10;
			}
			@media @mq[md] {
				grid-column: 1 / span 8;
			}
			@media @mq[sm] {
				grid-column: 1 / span 4;
			}
		}
		&__groupEmpty,
		&__groupFetching,
		&__groupResult {
			@media @mq[dt] {
				grid-column: 3 / span 8;
			}
			@media @mq[md] {
				grid-column: 2 / span 7;
			}
			@media @mq[sm] {
				grid-column: 1 / span 4;
			}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
