<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="AbMessageBoxAndText contentModule" :class="elmClasses">
		<div class="AbMessageBoxAndText__inner contentModule__inner layout hSpace hSpace--page maxWidth maxWidth--page" :class="elmInnerClasses">
			<div class="AbMessageBoxAndText__messageBoxWrapper">
				<div class="AbMessageBox background background--lightGold">
					<h3  class="AbMessageBox__title font font--serif font--colorWhite font--sizeDefault richText" v-html="messageBoxTitle"></h3>
					<div class="AbMessageBox__text font font--sans  font--colorWhite font--sizeSmall richText" v-html="messageBoxText"></div>
					<a   class="AbMessageBox__button button font--serif font--sizeSmall background--white color--pink" :href="buttonLink" v-html="buttonText"></a>
				</div>
			</div>
			<div class="AbMessageBoxAndText__textWrapper font font--sans font--sizeSmall richText" v-html="text"></div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'AbMessageBoxAndText',
		components: {},
		mixins: [],
		props: {
			layout: {
				type: [String, Boolean],
				default: '',
			},
			messageBoxTitle: {
				type: [String, Boolean],
				default: '',
			},
			messageBoxText: {
				type: [String, Boolean],
				default: '',
			},
			buttonText: {
				type: [String, Boolean],
				default: '',
			},
			buttonLink: {
				type: [String, Boolean],
				default: '',
			},
			text: {
				type: [String, Boolean],
				default: '',
			},
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
			elmInnerClasses(){
				let classes = []

				classes.push('layout--' + this.layout)

				return classes
			},
		},
		methods: {},
		created() {
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted() {},
		destroyed () {
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.AbMessageBoxAndText {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
