<!--

	Diese Componente fügt ein Consent-Overlay über dem Iframe ein
	und lädt diesen nur, wenn der User sein "Allow" gegeben hat.

	Beispiel Code:
		<MhHtmlEmbedConsentWrapper
			:htmlCode="'<iframe src="https://domain.com"></iframe>'"
			:overlayClass="''"
			:titleClass="''"
			:textClass="''"
			:buttonClass="''"
			:disclaimerClass="''"
			:removeConsentClass="''"
		></MhHtmlEmbedConsentWrapper>

	2024-05-06	rewrite
	2021-02-18	init

-->

<template>
	<div class="MhHtmlEmbedConsentWrapper" :class="elmClasses" v-if="htmlCode">
		<!--
			<pre name="currentState">{{ currentState }}</pre>
			<pre name="isIFrameAllowedToLoad">{{ isIFrameAllowedToLoad }}</pre>
			<button @click="giveConsent()">giveConsent</button>
			<button @click="removeConsent()">removeConsent</button>
		-->

		<div class="MhHtmlEmbedConsentWrapper__inner">
			<template v-if="isIFrameAllowedToLoad">
				<MhHtmlEmbed
					:htmlCode="htmlCode"
					:doLoadIframe="isIFrameAllowedToLoad"
				></MhHtmlEmbed>

				<a class="MhHtmlEmbedConsentWrapper__removeConsentButton"
					:class="removeConsentClass"
					@click="removeConsent">
					Zustimmung für das Laden von »{{iFrameHostname}}« widerrufen.
				</a>
			</template>
			<template v-else>
				<div class="MhHtmlEmbedConsentWrapper__overlay" :class="overlayClass">
					<h3 class="MhHtmlEmbedConsentWrapper__overlayTitle" :class="titleClass">
						Empfohlener externer Inhalt
					</h3>

					<p class="MhHtmlEmbedConsentWrapper__overlayText" :class="textClass">
						An dieser Stelle finden Sie zusätzliche Inhalte von »{{iFrameHostname}}«.
					</p>

					<div class="MhHtmlEmbedConsentWrapper__overlayExpander"></div>

					<div class="MhHtmlEmbedConsentWrapper__overlayButtonWrapper">
						<button class="MhHtmlEmbedConsentWrapper__overlayButton"
							:class="buttonClass"
							@click="giveConsent()"
						>Externen Inhalt anzeigen</button>
					</div>

					<div class="MhHtmlEmbedConsentWrapper__overlayExpander"></div>

					<p class="MhHtmlEmbedConsentWrapper__overlayDisclaimer" :class="disclaimerClass">
						Ich bin damit einverstanden, dass mir externe Inhalte angezeigt werden.
						Mit meiner Zustimmung können personenbezogene Daten an Drittplattformen übermittelt werden.
						<!--
						Weitere Informationen und den Widerruf der Zustimmung finden Sie in unserer
						<a href="">Datenschutzerklärung</a>.
						-->
					</p>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import MhHtmlEmbed from '@/vendor/MhHtmlEmbed/v2.2 • vue 2+3/MhHtmlEmbed.vue'

	export default {
		name: 'MhHtmlEmbedConsentWrapper',
		components: {
			MhHtmlEmbed,
		},
		mixins: [],
		props: {
			overlayClass: {
				type: [String, Boolean],
				default: '',
			},
			htmlCode: {
				type: [String, Boolean],
				default: '',
			},
			titleClass: {
				type: [String, Boolean],
				default: '',
			},
			textClass: {
				type: [String, Boolean],
				default: '',
			},
			buttonClass: {
				type: [String, Boolean],
				default: '',
			},
			disclaimerClass: {
				type: [String, Boolean],
				default: '',
			},
			removeConsentClass: {
				type: [String, Boolean],
				default: '',
			},
		},
		data(){
			return {
				localStorageKey : 'MhHtmlEmbedConsent',
				isIFrameAllowedToLoad : false,
				currentState : {},
			}
		},
		watch: {
			currentState( to ){
				if( to && this.currentState[this.iFrameHostname] ) this.isIFrameAllowedToLoad = true
				else this.isIFrameAllowedToLoad = false
			},
		},
		computed: {
			elmClasses(){
				let classes = []

				if( this.isIFrameAllowedToLoad ){
					classes.push( this.$options.name + '--isIFrameAllowedToLoad-true')
				}else{
					classes.push( this.$options.name + '--isIFrameAllowedToLoad-false')
				}

				return classes
			},
			iFrameHostname(){
				const div = document.createElement('div')
				div.innerHTML = this.htmlCode //.trim()

				let elm      = div.querySelector('iframe')
				let src      = elm ? elm.getAttribute('src') : false
				let domain   = src ? (new URL(src)) : false
				let hostname = domain ? domain.hostname : false

				return hostname
			},
		},
		methods: {
			giveConsent( doLog = false ){
				const hostname = this.iFrameHostname

				if( doLog ){
					console.group( this.$options.name + ' • giveConsent()')
					console.log('hostname:', hostname)
					console.groupEnd()
				}

				this.isIFrameAllowedToLoad = true

				let currentState = this.currentState
				currentState[hostname] = Math.floor(Date.now() / 1000)
				localStorage.setItem( this.localStorageKey, JSON.stringify(currentState) )
			},
			removeConsent( doLog = false ){
				const hostname = this.iFrameHostname

				if( doLog ){
					console.group( this.$options.name + ' • removeConsent()')
					console.log('hostname:', hostname)
					console.groupEnd()
				}

				this.isIFrameAllowedToLoad = false

				let currentState = this.currentState
				if( currentState.hasOwnProperty( hostname ) ){
					delete currentState[hostname]
				}
				localStorage.setItem( this.localStorageKey, JSON.stringify(currentState) )
			},
		},
		created(){},
		mounted( doLog = false ){
			// read stored consent settings
			let currentState = JSON.parse( localStorage.getItem( this.localStorageKey ) )
			currentState = currentState ? currentState : {}
			this.currentState = currentState

			// wenn für den hostname des iFrames zuvor ein allow erteilt
			// wurde, wird der iFrame gleich geladen
			if( currentState[this.iFrameHostname] ){
				this.isIFrameAllowedToLoad = true
			}

			// group groupCollapsed
			if( doLog ){
				console.group( this.$options.name + ' • mounted()')
				console.log('htmlCode:', this.htmlCode)
				console.log('currentState:', currentState)
				console.groupEnd()
			}

			// mimic reactive localStorage via continuously reading the storage, tja
			setInterval(() => {
				let currentState = JSON.parse( localStorage.getItem( this.localStorageKey ) )
				currentState = currentState ? currentState : {}

				this.currentState = currentState
			}, 1000)
		},
	}
</script>

<style lang="less">
	.MhHtmlEmbedConsentWrapper {
		//&__inner { background-color: fade( red, 20 ); }
	}
</style>
