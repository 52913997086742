<template>
	<div class="app handleHyphenation">
		<main class="app__body" :class="bodyClasses">
			<transition
				name="fade"
				mode="out-in"
				:duration="{ leave: 200, enter: 300  }"
				:key="$route.path"
				appear>
				<router-view class="app__bodyInner" :key="$route.path" />
			</transition>

			<div class="app__loadingSpinner"></div>
		</main>

		<MhSpinner class="app__loadingSpinner" :class="spinnerClasses"></MhSpinner>

		<MhHeadInfos
			:doLog="false"
			:ignoreViews="[
				'SearchResultsView',
				'SearchResultsView2',
				'SearchResultsView3',
			]"
		></MhHeadInfos>

		<MhCookieNotice></MhCookieNotice>
		<MhDelegateLinks></MhDelegateLinks>
		<MhDebugGrid></MhDebugGrid>
		<MhDevInfos
			:showOnHosts="[
				'localhost',
				'wp-kickstart-vue:8080'
			]"
		></MhDevInfos>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import resize from 'vue-resize-directive'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'

	import MhHeadInfos from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhHeadInfos/v1/MhHeadInfos.vue'
	import MhDevInfos from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhDevInfos/v2/MhDevInfos.vue'
	import MhDelegateLinks from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhDelegateLinks/v5/MhDelegateLinks.vue'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'
	import MhCookieNotice from '@/vendor/MhCookieNotice/MhCookieNotice.vue'
	import MhDebugGrid from '@/vendor/MhDebugGrid/MhDebugGrid.vue'
	import MhSpinner from '@/vendor/MhSpinner/MhSpinner.vue'

	export default {
		name: 'App',
		components: {
			MhHeadInfos,
			MhDevInfos,
			MhDelegateLinks,
			MhImage,
			MhDebugGrid,
			MhCookieNotice,
			MhSpinner,
		},
		directives: {
			resize,
		},
		mixins: [ restHandler ],
		data() {
			return {
				acfOptions : null,
				isVisible   : {
					body    : true,
					spinner : false,
				},
			}
		},
		computed: {
			bodyClasses(){
				let classes = []

				if( this.isVisible.body ) classes.push('app__body--isVisible')

				return classes
			},
			spinnerClasses(){
				let classes = []

				if( this.isVisible.spinner ) classes.push('app__loadingSpinner--isVisible')

				return classes
			},
		},
		methods: {
			getHostname(){	// for hidding the devInfos on some environments
				return window.location.hostname
			},
			viewEventHandler( payload, doLog = false ){
				const currentComponent = this.$options.name
				const fromComponent = payload.fromComponent
				const eventName = payload.eventName

				if( doLog ){
					console.groupCollapsed('%c' + currentComponent + ': $on("ViewEvent")', 'background-color: yellow', '•', fromComponent, eventName)
					console.log('fromComponent:', fromComponent)
					console.log('    eventName:', eventName)
					console.groupEnd()
				}

				if( ['beforeDestroy', 'beforeMount'].includes( eventName ) ){
					this.isVisible.body    = false
					this.isVisible.spinner = true
				}
				if( ['ready'].includes( eventName ) ){
					this.isVisible.body    = true
					this.isVisible.spinner = false
				}
			},
		},
		created() {
			EventBus.$on('ViewEvent', this.viewEventHandler)

			//window.addEventListener('popstate', this.popstateHandler )
		},
		mounted() {
			this.restHandler__fetch({
				action : 'GET',
				route : '/wp-json/mh/v1/acfOptions',
				params : {},
				callback : (response) => {
					this.acfOptions = response.data.result

					/*
					// hier wird aus allen introImages eins zufällig ausgewählt
					this.acfOptions.introImage = this._.sample( this.acfOptions.introImages )

					// um das layout handeln zu können sind die beiden
					// ersten menüpunkte, news + info, fest ins system integriert
					this.acfOptions.mainMenu__row1.unshift({
						title__de: "Info",
						title__en: "Info",
						type: "toggleSecondRow",
						page: false
					})
					this.acfOptions.mainMenu__row1.unshift({
						title__de: "News",
						title__en: "News",
						type: "homeView",
						page: false
					})

					this.$set(this.acfOptions, 'translations2', this.translations )
					*/
				},
			})

			// remove saved scroll position from sessionStorage
			// there where set by View.mixin.js
			const sessionStorageKeys = Object.keys( sessionStorage )
			sessionStorageKeys.forEach( (key)=>{
				if( key.includes(' scrollY') ){
					sessionStorage.removeItem( key )
				}
			})
		},
		beforeDestroy(){
			EventBus.$off('ViewEvent', this.viewEventHandler)
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import "@/less/includes/normalize.include.less";
	@import "@/less/fonts.less";
	@import "@/less/atoms.less";

	body {
		.font;
		.font--sans;
		.font--colorGrey;
		.font--sizeDefault;

		max-width: 100vw;
		overflow-y: auto;
		overflow-x: hidden;

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	strong, b {
		.font--bold;
	}
	button {
		cursor: pointer;
	}
	h1, h2, h3, h4, h5 {
		font-size: inherit;
		line-height: inherit;
		font-weight: inherit;
		margin: 0;
	}
	a {
		cursor: pointer;
		color: blue;
	}
	pre {
		@color: black;
		@fontSize: 14px;

		position: relative;
		padding: 0.5em;
		width: 100%;
		min-height: 2em;
		&[maxheight] { max-height: 300px; }

		margin-bottom: 0.5em;
		&:first-of-type { margin-top: 1em; }
		&:last-of-type { margin-bottom: 1em; }

		//color: fade(@color, 65);
		tab-size: 4;
		white-space: pre;
		font-size: @fontSize;
		line-height: 1.1em;

		outline: 1px solid fade(@color, 25);
		background-color: fade(@color, 5);
		overflow: auto;
		//display: none;

		// label
		&[name]:after {
			position: absolute;
			top: 0; right: 0;
			background-color: fade(@color, 15);
			font-size: @fontSize*0.75;
			line-height: 1em;
			font-family: sans-serif;
			color: fade(@color, 75);
			padding: 0.25em 0.5em;
			content: attr(name);
			font-family: inherit;
		}

		&:first-child {
			margin-top: 0em;
		}
	}
	hr {
		border: none;
		border-top: 1px solid;
		margin: 0.5em 0;
		opacity: 0.25;
	}
	ul, ol {
		margin-left: 1rem;
	}

	.layout { // handles responsive grid layouts
		& {
			.grid;
			.grid--setAreas;
			.grid--columnGap;

			@media @mq[dt] {
				.grid--cols-12;
			}
			@media @mq[md] {
				.grid--cols-8;
			}
			@media @mq[sm] {
				.grid--cols-4;
			}

		}
		[showBorders2] & > * {
			outline: 1px dotted fade(red, 80);
		}

		// text	(a)
		&--☰☰☰☰☰☰☰☰☰☰☰☰ {
			@media @mq[dt] {
				grid-template-areas: "a a a a a a a a a a a a";
			}
			@media @mq[md] {
				grid-template-areas: "a a a a a a a a";
			}
			@media @mq[sm] {
				grid-template-areas: "a a a a";
			}
		}
		&--□□☰☰☰☰☰☰☰☰□□ {
			@media @mq[dt] {
				grid-template-areas: ". . a a a a a a a a . .";
			}
			@media @mq[md] {
				grid-template-areas: ". . a a a a a a";
			}
			@media @mq[sm] {
				grid-template-areas: "a a a a";
			}
		}
		&--☰☰☰☰□□□□□□□□ {
			@media @mq[dt] {
				grid-template-areas: "a a a a . . . . . . . .";
			}
			@media @mq[md] {
				grid-template-areas: "a a a a . . . .";
			}
			@media @mq[sm] {
				grid-template-areas: "a a a a";
			}
		}
		&--□□□□☰☰☰☰☰☰□□ {
			@media @mq[dt] {
				grid-template-areas: ". . . . a a a a a a . .";
			}
			@media @mq[md] {
				grid-template-areas: ". . . a a a a a";
			}
			@media @mq[sm] {
				grid-template-areas: "a a a a";
			}
		}
		&--□□□□□□□□☰☰☰☰ {
			@media @mq[dt] {
				grid-template-areas: ". . . . . . . . a a a a";
			}
			@media @mq[md] {
				grid-template-areas: ". . . . a a a a";
			}
			@media @mq[sm] {
				grid-template-areas: "a a a a";
			}
		}

		// text (a) + image (b)
		&--▨▨▨▨▨▨▨□☰☰☰☰ {
			@media @mq[dt] {
				grid-template-areas: "b b b b b b b . a a a a";
			}
			@media @mq[md] {
				grid-template-areas: "b b b b b . . ."
									 ". . . a a a a a";
			}
			@media @mq[sm] {
				grid-template-areas: "b b b b"
									 "a a a a";
			}
		}
		&--□□□▨▨▨▨□☰☰☰☰ {
			@media @mq[dt] {
				grid-template-areas: ". . . b b b b . a a a a";
			}
			@media @mq[md] {
				grid-template-areas: "b b b a a a a a"
			}
			@media @mq[sm] {
				/*
				grid-template-areas: "b b b ."
									 "a a a a";
				*/
				grid-template-areas: "a a a a"
									 "b b b .";
			}
		}
		&--☰☰☰☰□▨▨▨▨▨▨▨ {
			@media @mq[dt] {
				grid-template-areas: "a a a a . b b b b b b b";
			}
			@media @mq[md] {
				grid-template-areas: ". . . b b b b b"
									 "a a a a a . . .";
			}
			@media @mq[sm] {
				grid-template-areas: "a a a a"
									 "b b b b";
			}
		}
		&--☰☰☰☰□▨▨▨▨□□□ {
			@media @mq[dt] {
				grid-template-areas: "a a a a . b b b b . . .";
			}
			@media @mq[md] {
				grid-template-areas: "a a a a a b b b";
			}
			@media @mq[sm] {
				grid-template-areas: "a a a a"
									 "b b b .";
			}
		}

		// image (a)
		&--▨▨▨▨□ {
			@media @mq[dt] {
				.grid--cols-7;
				grid-template-areas: "a a a a a a .";
			}
			@media @mq[md] {
				.grid--cols-5;
				grid-template-areas: "a a a a .";
			}
			@media @mq[sm] {
				.grid--cols-4;
				grid-template-areas: "a a a a";
			}
		}
		&--▨▨▨▨▨□ {
			@media @mq[dt] {
				.grid--cols-7;
				grid-template-areas: "a a a a a a a";
			}
			@media @mq[md] {
				.grid--cols-5;
				grid-template-areas: "a a a a a";
			}
			@media @mq[sm] {
				.grid--cols-4;
				grid-template-areas: "a a a a";
			}
		}
		&--□□▨▨▨▨▨▨▨▨□□ {
			@media @mq[dt] {
				grid-template-areas: ". . a a a a a a a a . .";
			}
			@media @mq[md] {
				grid-template-areas: ". a a a a a a .";
			}
			@media @mq[sm] {
				grid-template-areas: "a a a a";
			}
		}

		// messageBox (a ) + image (b)
		&--■■■■■▨▨▨▨▨▨▨ {
			@media @mq[dt] {
				grid-template-areas: "a a a a a b b b b b b b";
			}
			@media @mq[md] {
				grid-template-areas: "a a a a b b b b";
			}
			@media @mq[sm] {
				grid-template-areas: "a a a a"
									 "b b b b";
			}
		}
		&--▨▨▨▨▨▨▨■■■■■ {
			@media @mq[dt] {
				grid-template-areas: "b b b b b b b a a a a a";
			}
			@media @mq[md] {
				grid-template-areas: "b b b b a a a a";
			}
			@media @mq[sm] {
				grid-template-areas: "b b b b"
									 "a a a a";
			}
		}

		// messageBox (a ) + text (b)
		&--■■■■■□☰☰☰☰☰☰ {
			@media @mq[dt] {
				grid-template-areas: "a a a a a . b b b b b b";
			}
			@media @mq[md] {
				grid-template-areas: "a a a a b b b b";
			}
			@media @mq[sm] {
				grid-template-areas: "a a a a"
									 "b b b b";
			}
		}
		&--☰☰☰☰☰☰□■■■■■ {
			@media @mq[dt] {
				grid-template-areas: "b b b b b b . a a a a a";
			}
			@media @mq[md] {
				grid-template-areas: "b b b b a a a a";
			}
			@media @mq[sm] {
				grid-template-areas: "b b b b"
									 "a a a a";
			}
		}

		// teaserBoxAndImage
		&--▨▨▨▨▨□☰☰☰☰☰☰ {
			@media @mq[dt] {
				grid-template-areas: "a a a a a . b b b b b b";
			}
			@media @mq[md] {
				grid-template-areas: "a a a a b b b b";
			}
			@media @mq[sm] {
				grid-template-areas: "a a a a"
									 "b b b b";
			}
		}
		&--☰☰☰☰☰☰□▨▨▨▨▨ {
			@media @mq[dt] {
				grid-template-areas: "b b b b b b . a a a a a";
			}
			@media @mq[md] {
				grid-template-areas: "b b b b a a a a";
			}
			@media @mq[sm] {
				grid-template-areas: "b b b b"
									 "a a a a";
			}
		}

		// html-embed
		&--▨▨▨▨▨▨▨▨▨▨▨▨ {
			@media @mq[dt] {
				grid-template-areas: "a a a a a a a a a a a a";
			}
			@media @mq[md] {
				grid-template-areas: "a a a a a a a a";
			}
			@media @mq[sm] {
				grid-template-areas: "a a a a";
			}
		}
		&--□□▨▨▨▨▨▨▨▨□□ {
			@media @mq[dt] {
				grid-template-areas: ". . a a a a a a a a . .";
			}
			@media @mq[md] {
				grid-template-areas: ". a a a a a a .";
			}
			@media @mq[sm] {
				grid-template-areas: "a a a a";
			}
		}
	}
	.contentModule { // debug outlining
		[showBorders2] & {
			outline: 1px solid fade(red, 80);
			//.outline( red );
			.label( attr(class), fade(red, 80), black );
		}

		[showBorders2] &__inner {
			outline: 1px dotted fade(red, 80);
		}
	}
	.contentModule { // general settings
		position: relative;

		&__inner {
			position: relative;
			margin: 0 auto;
		}
	}
	.handleHyphenation {
		.AbHomeFeature__title,
		.AbEventAndNachlese__bodyTitle,
		.richText {
			//background-color: yellow !important;
			hyphens: auto;
		}
	}
	.richText { // handle links usw.
		a {
			transition: all 0.1s ease;
			color: inherit;
			text-decoration: none;
			border-bottom: 1px solid fade( black, 25 );
		}
		a:hover {
			border-bottom-color: currentColor;
		}

		&.font--colorWhite a {
			border-bottom-color: fade( white, 25 );
		}
		&.font--colorWhite a:hover {
			border-bottom-color: white;
		}
	}
	.button {
		display: inline-flex;
		font-size: 16px;
		text-decoration: none;
		padding: 0.55em 1em;
	}

	.AbMessageBox {
		& > *:empty { display: none; }

		@media @mq[dt] {
			padding: 25px;
		}
		@media @mq[md] {
			padding: 22px;
		}
	}
	.AbMessageBox__title {
		@media @mq[dt] {
			padding-bottom: 20px;
		}
		@media @mq[md] {
			padding-bottom: 20px;
		}
	}
	.AbMessageBox__text {}
	.AbMessageBox__button {
		margin-top: 1.5em;
	}

	.app {
		min-height: 100vh;
	}
	.app__body {
		transition: all 0.2s ease;
		pointer-events: none;
		opacity: 0.5;

		&--isVisible { opacity: 1; pointer-events: auto; }
	}
	.app__bodyInner {
		//background-color: fade( white, 90 );
	}
	.app__loadingSpinner { // deaktiviert: läuft zu hakelig, dann besser ganz ohne
		display: none;

		position: fixed;
		top: 50vh;
		left: 50vw;

		//transition: all 0.2s ease;
		transform: translateX(-50%) translateY(-50%) scale(1.2);
		pointer-events: none;
		opacity: 0;

		&--isVisible { opacity: 1; }
	}

	@transitionDuration : 100ms;
	@transitionDelay : 100ms;

	.fade-enter-active,
	.fade-leave-active {
		transition-duration: @transitionDuration;
		transition-property: opacity;
		transition-timing-function: ease;
	}
	.fade-enter-active {
		transition-delay: @transitionDelay !important;
	}
	.fade-enter,
	.fade-leave-active {
		opacity: 0;
		transition-delay: @transitionDelay !important;
	}


	// CleverReach Formular Styling
	// Der Quellcode für das Form wird direkt von der CleverReach-Seite kopiert:
	// https://eu2.cleverreach.com/admin/forms_layout_embed.php?id=387555&layout=subscribe
	// Wichtig ist, dass beim Quellcode kopieren Javascript, CSS und das Banner deaktiviert wurde
	form[action*="cleverreach"] {
		--label-width: 4em;
		--input-height: 2.5em;

		.editable_content {
			display: flex;
			flex-direction: column;
			gap: 0.25em;
		}
		.cr_form-inputgroup.cr_form-inputgroup--typeemail {
			display: flex;
		}
		.cr_form-component--submit {
			padding-top: 0.35em;
			padding-left: var(--label-width);
		}

		label {
			display: inline-flex;
			align-items: center;
			height: var(--input-height);
			width: var(--label-width);
			flex-shrink: 0;
		}
		input {
			font: inherit;
			height: var(--input-height);
			background-color: #f2f2f2;
			border: none;
			flex-grow: 1;
			flex-shrink: 0;
			padding: 0.5em;
		}
		button {
			display: inline-flex;
			text-decoration: none;
			padding: 0.55em 1em;
			font: inherit;
			border: none;
			background-color: @swatches[blue];
			color: @swatches[white];
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
