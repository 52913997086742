<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="MhDebugGrid" :class="elmClasses">
		<div class="MhDebugGrid__columns" :class="elmInnerClasses">
			<div class="MhDebugGrid__colum"></div>
			<div class="MhDebugGrid__colum"></div>
			<div class="MhDebugGrid__colum"></div>
			<div class="MhDebugGrid__colum"></div>
			<div class="MhDebugGrid__colum"></div>
			<div class="MhDebugGrid__colum"></div>
			<div class="MhDebugGrid__colum"></div>
			<div class="MhDebugGrid__colum"></div>

			<div class="MhDebugGrid__colum"></div>
			<div class="MhDebugGrid__colum"></div>
			<div class="MhDebugGrid__colum"></div>
			<div class="MhDebugGrid__colum"></div>
		</div>
	</div>

</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'MhDebugGrid',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			elmInnerClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {},
		created() {
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted() {},
		destroyed () {
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import "../../less/vars.less";
	@import (reference) "../../less/mixins.less";
	@import (reference) "../../less/atoms.less";

	@MhDebugGrid : {
		color : fade( green, 20 );
	}

	.MhDebugGrid {
		position: fixed;
		top: 0; left: 0; right: 0; bottom: 0;
		pointer-events: none;
		display: flex;
		justify-content: center;
		visibility: hidden;
		z-index: 100;

		[showBorders1] & {
			visibility: visible;
		}

		& > * {
 			flex-grow: 1;
			height: 100vh;
		}
	}
	.MhDebugGrid__columns {	// colums number and gap
		.grid;
		.hSpace;
		.hSpace--page;
		.maxWidth;
		.maxWidth--page;

		grid-template-rows: 1fr 0px; // hides columns that slip eventully into the second row

		@media @mq[dt] {
			.grid--cols-12;
			grid-column-gap: 24px;
		}
		@media @mq[md] {
			.grid--cols-8;
			grid-column-gap: 20px;
		}
		@media @mq[sm] {
			.grid--cols-4;
			grid-column-gap: 10px;
		}
	}
	.MhDebugGrid__colum {
		background-color: @MhDebugGrid[color];
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
