import Vue from 'vue'
import Router from 'vue-router'
import HomeView from '@/views/HomeView.vue'

Vue.use(Router)

// Handle scrollRestoration manual, stops browser from doing it
if('scrollRestoration' in window.history) {
	window.history.scrollRestoration = 'manual';
}

export default new Router({
	base: '/',
	mode: 'history',
	linkActiveClass : 'routerLink--active',
	linkExactActiveClass : 'routerLink--exactActive',
	/*
	scrollBehavior( to, from, savedPosition ) {
		if (savedPosition) {
			//console.log('2')
			return savedPosition
			//return
		} else {
			//return
			return { x: 0, y: 0 }
		}
	},
	*/
	routes: [
		{	path: '/',
			name: 'HomeView',
			component: HomeView,
			pathToRegexpOptions: { strict: true },
		},
		{	path: '/veranstaltungen/',
			name: 'EventsView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "EventsView" */ '@/views/EventsView.vue')
		},
		{	path: '/veranstaltungen/:slug/',
			name: 'EventView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "EventView" */ '@/views/EventView.vue')
		},
		{ 	path: '/aus-dem-kloster/',
			name: 'NachlesesView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "NachleseView" */ '@/views/NachleseView.vue')
		},
		{ 	path: '/aus-dem-kloster/:slug/',
			name: 'NachleseViewDetail',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "NachleseView" */ '@/views/NachleseView.vue')
		},
		{	path: '/search/',
			name: 'SearchResultsView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "SearchResultsView" */ '@/views/SearchResultsView.vue')
		},
		{	path: '/search/:searchString',
			name: 'SearchResultsView2',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "SearchResultsView" */ '@/views/SearchResultsView.vue')
		},
		{	path: '/search/:searchString/',
			name: 'SearchResultsView3',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "SearchResultsView" */ '@/views/SearchResultsView.vue')
		},
		{	path: '/nachlese/:slug/',
			name: 'NachleseView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "NachleseView" */ '@/views/NachleseView.vue')
		},
		{	path: "/:slug/",
			name: 'PageView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "PageView" */ '@/views/PageView.vue')
		},
		{	path: "*",
			name: 'NotFoundView',
			component: () =>
				import ( /* webpackChunkName: "NotFoundView" */ '@/views/NotFoundView.vue')
		},
	]
})
