<!--

	Diese Component gibt einen IFrame im richtigen Seitenverhältnis,
	definiert anhand der width + height attr des iframe-tags,
	mit 100% Breite aus.

	Welche $props gibt es?
		htmlCode = html string, e.g. '<iframe .... ></iframe>'

	Beispiel Code:
		<MhHtmlEmbed
			:htmlCode="'<iframe src=""></iframe>'"
			:doLoadIframe="true"
		></MhHtmlEmbed>

	2023-11-03	bugfix: hide everything exept iframes
	2023-11-03	bugfix: not padding-bottom for width="100%"
	2022-05-16	feature: added vue 3 support
	2020-12-28	init

-->

<template>
	<div class="MhHtmlEmbed" :class="elmClasses" v-if="htmlCode">
		<!--
			<pre>width : {{ width }}</pre>
			<pre>height : {{ height }}</pre>
		-->
		<div class="MhHtmlEmbed__inner">
			<div class="MhHtmlEmbed__embedWrapper" :style="{ paddingBottom }" ref="innerElm">
				<div class="MhHtmlEmbed__embedInner" :class="paddingBottom ? 'MhHtmlEmbed__embedInner--hasAspectRatio' : ''" v-html="htmlString" v-if="doLoadIframe"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import { isNaN } from 'lodash'
	import { endsWith } from 'lodash'

	export default {
		name: 'MhHtmlEmbed',
		components: {},
		mixins: [],
		props: {
			htmlCode: {
				type: [String, Boolean],
				default: '',
			},
			doLoadIframe: {
				type: [String, Boolean],
				default: true,
			},
		},
		data() {
			return {
				paddingBottom : null, // defines elm ratio
				width : null,
				height : null,
			}
		},
		watch: {
			htmlCode: {
				handler: function( to, from ) {
					this.extractIframeDimensions( to )
				},
				immediate : true,
				deep: true,
			}
		},
		computed: {
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			htmlString(){
				let htmlCode   = this.htmlCode
				let htmlString = htmlCode

				return htmlString
			},
		},
		methods: {
			extractIframeDimensions( htmlCode, doLog = true ) {
				const div  = document.createElement('div')
				let elm    = undefined
				let width  = undefined
				let height = undefined
				let ratio  = undefined

				if( htmlCode ){
					div.innerHTML = htmlCode.trim()

					elm    = div.querySelector('iframe')
					elm    = elm ? elm : undefined
					width  = elm ? elm.getAttribute('width') : undefined
					height = elm ? elm.getAttribute('height') : undefined

					//if( endsWith( width, '%') && this.$refs.innerElm ) width = this.$refs.innerElm.offsetWidth

					ratio  = (width && height) ? ((height/width)*100) + '%' : undefined

					//this.paddingBottom = ratio
					this.paddingBottom = !isNaN((height/width)*100) ? ratio : undefined
					this.width = width
					this.height = height
				}

				if( doLog ){
					console.group('MhHtmlEmbed • extractIframeDimensions()')
					console.log('htmlCode:', htmlCode)
					console.log('elm:', elm)
					console.log('width:', width)
					console.log('height:', height)
					console.log('ratio:', ratio)
					console.log('isNaN(width):', isNaN(width))
					console.log('isNaN(height):', isNaN(height))
					console.log('isNaN((height/width)*100):', isNaN((height/width)*100))
					//console.log('width:', width)
					//console.log('height:', height)
					console.groupEnd()
				}

				return elm
			}
		},
		created() {},
		mounted() {
			this.extractIframeDimensions()
		},
	}
</script>

<style lang="less">
	.MhHtmlEmbed {
		position: relative;

		&__embedWrapper {
			position: relative;
		}
		&__embedWrapper iframe {
			display: block; // prevents bottom margin
		}
		&__embedInner--hasAspectRatio {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			background-color: fade( black, 10 );

			& > * { display: none; } // hide everything but iframes

			iframe {
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;
				height: 100%; width: 100%;
				display: block;
			}
		}
		&__embedInner > *:not(iframe) { display: none; }
	}
</style>
